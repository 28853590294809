import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MultiSelectChipInput,
} from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import { MeetingStatus } from "@src/api/graphql";
import compact from "@src/lib/compact";
import { toCSVBlob } from "@src/lib/toCSV";
import { isRequiredLength } from "@src/lib/validate";
import { Facility } from "@src/typings/Facility";
import { saveAs } from "file-saver";
import i18n from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BASE_REPORT_FIELDS,
  ExportableField,
  exportableFieldLabel,
  meetingsToCsvLogs,
} from "../Call";
import { meetingTypeTitle } from "../meeting";
import { yearMonthDate } from "../Time";
import useFullSchedule, { Filters } from "./useFullSchedule";

export type Props = {
  facility: Pick<Facility, "id" | "name">;
  filters: Filters;
  defaultFields?: ExportableField[];
  onClose: () => void;
};
const meetingStatusLabels: Record<MeetingStatus, string> = {
  [MeetingStatus.Cancelled]: i18n.t("Canceled"),
  [MeetingStatus.Ended]: i18n.t("Ended"),
  [MeetingStatus.Live]: i18n.t("Live"),
  [MeetingStatus.NoShow]: i18n.t("No show"),
  [MeetingStatus.PendingApproval]: i18n.t("Pending approval"),
  [MeetingStatus.Rejected]: i18n.t("Declined"),
  [MeetingStatus.Scheduled]: i18n.t("Scheduled"),
  [MeetingStatus.Terminated]: i18n.t("Terminated"),
};

type FormData = {
  fields: ExportableField[];
};

const ExportMeetingsModal: React.FC<Props> = ({
  filters,
  facility,
  onClose,
  defaultFields = BASE_REPORT_FIELDS,
}) => {
  const [generating, setGenerating] = useState(false);

  const { t } = useTranslation();

  const dateRange = compact([
    filters.scheduledStartAfter,
    filters.scheduledStartBefore,
  ])
    .map(yearMonthDate)
    .join("-");
  const fileName = `Logs ${facility.name} ${dateRange} - ${Date.now()}`;

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      fields: defaultFields,
    },
  });

  const { data, loading } = useFullSchedule({
    facilityId: facility.id,
    filters,
  });

  const saveAndClose = handleSubmit(({ fields }) => {
    setGenerating(true);
    const blob = toCSVBlob(meetingsToCsvLogs(data, fields));
    saveAs(blob, `${fileName}.csv`);
    onClose();
  });

  return (
    <Dialog
      fullWidth
      title={t("Export {{type}} logs", {
        type: filters.meetingType
          ? meetingTypeTitle(filters.meetingType)
          : "meeting",
      })}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={{ flexDirection: "row" }}>
            <Typography component="label" variant="body1" fontWeight="bold">
              {t("Dates")}
            </Typography>
            <div>
              {yearMonthDate(filters.scheduledStartAfter)}
              {" - "}
              {filters.scheduledStartBefore &&
                yearMonthDate(filters.scheduledStartBefore)}
            </div>
          </Box>
          <Box sx={{ flexDirection: "row" }}>
            <Typography component="label" variant="body1" fontWeight="bold">
              {t("Statuses")}
            </Typography>
            <div>
              {filters.meetingStatus.length > 0
                ? filters.meetingStatus
                    .map((s) => meetingStatusLabels[s])
                    .join(", ")
                : t("ALL")}
            </div>
          </Box>
          <Box sx={{ flexDirection: "row" }}>
            <Typography component="label" variant="body1" fontWeight="bold">
              {t("Location")}
            </Typography>
            <div>{filters.group?.name ?? t("ALL")}</div>
          </Box>
          <Box sx={{ flexDirection: "row" }}>
            <Typography component="label" variant="body1" fontWeight="bold">
              {t("Fields")}
            </Typography>
            <MultiSelectChipInput
              control={control}
              name="fields"
              keepMenuOpen
              rules={isRequiredLength(t("Please select at least one field"))}
              items={Object.values(ExportableField).map((f) => {
                return {
                  name: exportableFieldLabel(f),
                  value: f,
                };
              })}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!isValid || loading || generating}
          onClick={() => (loading || generating ? null : saveAndClose())}
        >
          {loading
            ? t("Loading...")
            : generating
              ? t("Generating...")
              : t("Export")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportMeetingsModal;
