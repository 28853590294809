import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import { ConnectionRestriction } from "@src/api/graphql";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { isRequired } from "@src/lib/validate";
import { endOfDay } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { fromLocalISODate, toLocalISODate } from "../../Time";
import ExpiresAtInput from "./ExpiresAtInput";
import { UpdateConnectionRestrictionDocument } from "./UpdateConnectionRestriction.generated";

type Data = {
  expiresAt: string | null;
  reason: string;
};

type Props = {
  restriction: Pick<
    ConnectionRestriction,
    "id" | "service" | "expiresAt" | "reason"
  >;
  onClose: () => void;
};

export default function EditRestrictionDialog({ restriction, onClose }: Props) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<Data>({
    mode: "onTouched",
    defaultValues: {
      expiresAt: restriction.expiresAt
        ? toLocalISODate(restriction.expiresAt)
        : null,
      reason: restriction.reason,
    },
  });
  const handleApolloError = useApolloErrorHandler();
  const [updateConnectionRestrictionMutation] = useMutation(
    UpdateConnectionRestrictionDocument,
    {
      onError: handleApolloError,
      onCompleted: () => {
        onClose();
      },
    },
  );

  const doSubmit = async (data: Data) => {
    const expiresAt = data.expiresAt
      ? endOfDay(fromLocalISODate(data.expiresAt)).getTime()
      : undefined;
    await updateConnectionRestrictionMutation({
      variables: {
        input: {
          connectionRestrictionId: restriction.id,
          expiresAt,
          reason: data.reason,
        },
      },
    });
  };

  return (
    <Dialog
      title={t("Edit restriction")}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Stack spacing={2} py={2}>
          <TextInput
            control={control}
            name="reason"
            label={t("Explanation")}
            multiline
            rows={3}
            rules={isRequired(t("Please provide an explanation."))}
          />

          <ExpiresAtInput name="expiresAt" control={control} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <SubmitButton
          submitting={isSubmitting}
          onClick={handleSubmit(doSubmit)}
        >
          {t("Save")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
