import { Button, useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { TranscriptStatus } from "@src/api/graphql";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Result from "@src/lib/Result";
import ScreenSection from "@src/lib/ScreenSection";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetCallWithRecordingQuery } from "../GetCallWithRecording.generated";
import { DownloadTranscriptDocument } from "./DownloadTranscript.generated";

type Props = {
  meeting: GetCallWithRecordingQuery["meeting"];
  transcriptStatus: TranscriptStatus;
  transcriptContent: string;
};

const DownloadTranscriptButton = ({ meetingId }: { meetingId: string }) => {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const [downloading, setDownloading] = useState(false);
  const handleApolloError = useApolloErrorHandler();
  const [downloadTranscript] = useMutation(DownloadTranscriptDocument, {
    onError: handleApolloError,
    onCompleted: (data) => {
      const { url } = data.downloadTranscript;
      if (url) {
        const downloadLink = document.createElement("a");
        downloadLink.target = "_blank";
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        snackbarContext.alert("error", t("Fetch of PDF url failed."));
      }
      setDownloading(false);
    },
  });

  return (
    <Button
      onClick={() => {
        setDownloading(true);
        void downloadTranscript({
          variables: {
            input: {
              meetingId: meetingId,
            },
          },
        });
      }}
      key="2"
      variant="contained"
      disabled={downloading}
    >
      <DownloadOutlined style={{ verticalAlign: "middle" }} />
      <span style={{ verticalAlign: "middle", marginLeft: 8 }}>
        {t("Download transcript")}
      </span>
    </Button>
  );
};

export default function TranscriptModule({
  meeting,
  transcriptStatus,
  transcriptContent,
}: Props) {
  const { t } = useTranslation();
  const errorTitle = (() => {
    switch (transcriptStatus) {
      case TranscriptStatus.NoTranscript:
        return t("No transcript available");
      case TranscriptStatus.Pending:
        return t("In progress");
      case TranscriptStatus.Expired:
        return t("Expired link");
      case TranscriptStatus.Available:
        return "";
    }
  })();

  const errorSubtitle = (() => {
    switch (transcriptStatus) {
      case TranscriptStatus.NoTranscript:
        return t("");
      case TranscriptStatus.Pending:
        return t(
          "This transcript is still processing. Please check back later.",
        );
      case TranscriptStatus.Expired:
        return t(
          "All transcripts permanently expire {{count}} days after the meeting start time.",
          {
            count: meeting.facility.system.recordingRetention,
          },
        );
      case TranscriptStatus.Available:
        return "";
    }
  })();
  return (
    <ScreenSection
      title={t("Call transcript")}
      sx={{ mb: 2 }}
      titlePosition="inner"
      extra={
        transcriptStatus === TranscriptStatus.Available ? (
          <DownloadTranscriptButton meetingId={meeting.id} />
        ) : null
      }
    >
      {transcriptStatus === TranscriptStatus.Available && (
        <>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ fontWeight: 500 }}
          >
            {t(
              "Note: Transcripts are created automatically and have not been reviewed for accuracy. They do not include non-verbal sounds, and contain inaccuracies in words, time stamps, and speaker assignment.",
            )}
          </Typography>
          <Box maxHeight={500} overflow="auto" pt={2}>
            {transcriptContent.split("\n\n").map((segment, index) => (
              <Box p={1} key={`${segment}_${index}`}>
                <Typography variant="body1" color="text.primary">
                  {segment}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      {transcriptStatus !== TranscriptStatus.Available && (
        <Result variant="404" title={errorTitle} subTitle={errorSubtitle} />
      )}
    </ScreenSection>
  );
}
