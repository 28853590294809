import { Stack, Typography } from "@mui/material";

export function TranscriptSegment({ segment }: { segment: string }) {
  return (
    <Typography variant="body2" color="text.primary">
      {segment.split(" ").map((word, idx) => (
        <span key={`${word}_${idx}`}>
          <span
            style={
              word.includes("<b>")
                ? {
                    backgroundColor: "#FFFDBA",
                    fontWeight: "bold",
                  }
                : {}
            }
          >
            {word.replace(/<b>/g, "").replace(/<\/b>/g, "")}
          </span>{" "}
        </span>
      ))}
    </Typography>
  );
}

export default function TranscriptPreview({
  segments,
}: {
  segments: string[];
}) {
  const firstSegmentQueryMatch = segments.findIndex((segment: string) =>
    segment.split(" ").find((word: string) => word.includes("<b>")),
  );
  // Used to center the segment containing the first "match" to the query
  // in the preview cell in the table row
  const previewSegments =
    firstSegmentQueryMatch > 0
      ? segments.slice(firstSegmentQueryMatch - 1, firstSegmentQueryMatch + 2) // center it
      : segments.slice(0, 3);
  return (
    <Stack spacing={1}>
      {previewSegments.map((segment, idx) => (
        <TranscriptSegment key={`${segment}_${idx}`} segment={segment} />
      ))}
    </Stack>
  );
}
