import { Language } from "@src/api/graphql";

const languageToI18n: Record<Language, string> = {
  [Language.En]: "en",
  [Language.Es]: "es",
  [Language.It]: "it",
};

export const LanguageNames = {
  [Language.En]: "English",
  [Language.Es]: "Español",
  [Language.It]: "Italiano",
};

export const inferBrowserLanguage = (): string => {
  const languageCode = navigator.language.split("-").shift()?.toUpperCase();
  switch (languageCode) {
    case Language.En:
    case Language.It:
      // case Language.Es:
      return languageToI18n[languageCode];
    default:
      return languageToI18n[Language.En];
  }
};
