import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  Connection,
  ConnectionStatus,
  OrganizationMembership,
  SystemUser,
  Visitor,
  VisitorIdentity,
} from "@src/api/graphql";
import { GetInmateConnectionsDocument } from "@src/graphql/GetInmateConnections.generated";
import Alert from "@src/lib/Alert";
import DetailsStack, { DetailsStackItem } from "@src/lib/DetailsStack";
import InmateContactCard, {
  Props as InmateContactCardProps,
} from "@src/lib/InmateContactCard";
import OrganizationCard, {
  Props as OrganizationCardProps,
} from "@src/lib/OrganizationCard";
import { isConfidential } from "@src/lib/securityFeatureUtils.ts";
import { isRequired } from "@src/lib/validate";
import VisitorContactCard from "@src/lib/VisitorContactCard";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type ReviewableConnection = Pick<
  Connection,
  "id" | "relationship" | "security"
> & {
  visitor: Pick<Visitor, "id" | "email" | "dateOfBirth" | "fullName"> & {
    identity?: Pick<VisitorIdentity, "id"> | null;
    systemRelationship?: Pick<SystemUser, "id" | "status"> | null;
  };
  organizationMembership?:
    | (Pick<OrganizationMembership, "id"> & OrganizationCardProps)
    | null;
} & InmateContactCardProps;

type Props = {
  connection: ReviewableConnection;
  lastStep: boolean;
  onCancel: () => void;
  onApprove: () => void;
  onReject: (reason: string) => void;
};

type FormData = {
  decision: "" | "reject" | "approve";
  reason: string;
  otherReason: string;
};

export default function ConnectionReviewModal({
  connection,
  lastStep,
  onCancel,
  onApprove,
  onReject,
}: Props) {
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      decision: "",
      reason: "",
      otherReason: "",
    },
  });

  const { data: inmateConnectionsData } = useQuery(
    GetInmateConnectionsDocument,
    {
      fetchPolicy: "cache-and-network",
      variables: { id: connection.inmate.id },
    },
  );

  const { t } = useTranslation();

  const OTHER = t("Other");
  const OTHER_PREAMBLE = t(
    "Unfortunately, we cannot approve this request because:",
  );
  const REJECTION_REASONS = [
    t(
      "You are not in our records yet. Please fill out a visitor application and try again.",
    ),
    t("The resident has declined visitation."),
    t("The resident has been released."),
    OTHER,
  ];

  const doSubmit = (data: FormData) => {
    if (data.decision === "reject") {
      onReject(
        data.reason === OTHER
          ? `${OTHER_PREAMBLE} ${data.otherReason}`
          : data.reason,
      );
    } else {
      onApprove();
    }
  };

  const decision = watch("decision");
  const reason = watch("reason");
  const { visitor, inmate, organizationMembership } = connection;

  const actionMessage = isConfidential(connection.security)
    ? t("Approve or reject confidential contact request?")
    : t("Approve or reject contact request?");
  // A pending contact is considered a possible duplicate if the
  // inmate already has an active connection with another visitor
  // having the same name and date of birth as the pending contact
  const possibleDuplicateContact = inmateConnectionsData?.inmate.connections
    .filter((c) => c.status === ConnectionStatus.Active)
    .some(
      (c) =>
        c.visitor.fullName.toLowerCase().trim() ===
          visitor.fullName.toLowerCase().trim() &&
        c.visitor.dateOfBirth === visitor.dateOfBirth,
    );

  return (
    <Dialog
      title={t("Review contact request")}
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
    >
      <DialogContent>
        {possibleDuplicateContact && (
          <Alert severity="warning" sx={{ mb: 4 }}>
            {t(
              "This visitor may be a duplicate of an existing approved contact. You may want to check the visitor directory to confirm before approving.",
            )}
          </Alert>
        )}
        <DetailsStack>
          <DetailsStackItem label={t("Visitor")} typographyVariant="body2">
            <VisitorContactCard connection={connection} />
          </DetailsStackItem>
          {organizationMembership && (
            <DetailsStackItem
              label={t("Organization")}
              typographyVariant="body2"
            >
              <OrganizationCard
                organization={organizationMembership.organization}
              />
            </DetailsStackItem>
          )}
          <DetailsStackItem label={t("Resident")} typographyVariant="body2">
            <InmateContactCard inmate={inmate} />
          </DetailsStackItem>
        </DetailsStack>
        <form onSubmit={handleSubmit(doSubmit)}>
          <Box sx={{ mt: 2, px: 2, py: 1.5, backgroundColor: grey[50] }}>
            <Typography variant="subtitle2" color="text.primary">
              {actionMessage}
            </Typography>
            <Controller
              control={control}
              name="decision"
              rules={isRequired(t("Please make a decision."))}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="approve"
                    control={<Radio />}
                    label={
                      <Typography variant="body2" color="text.primary">
                        {t("Approve")}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={<Radio />}
                    label={
                      <Typography variant="body2" color="text.primary">
                        {t("Reject")}
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
            />
            {decision === "reject" && (
              <Box mt={2} style={{ display: "flex", flexDirection: "column" }}>
                <Box mb={2}>
                  <Typography variant="subtitle2" color="text.primary">
                    {t("Why is this request being rejected?")}
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name="reason"
                  rules={isRequired(t("Please select a reason."))}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {REJECTION_REASONS.map((reason) => (
                        <FormControlLabel
                          key={reason}
                          control={<Radio />}
                          value={reason}
                          label={
                            <Typography variant="body2" color="text.primary">
                              {reason}
                            </Typography>
                          }
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
                {reason === OTHER && (
                  <TextInput
                    control={control}
                    name="otherReason"
                    label={OTHER_PREAMBLE}
                    rules={isRequired(t("Please provide a reason."))}
                    multiline
                    rows={2}
                    sx={{ ml: 4, flex: 1 }}
                  />
                )}
              </Box>
            )}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <SubmitButton
          disabled={!isValid}
          submitting={isSubmitting}
          onClick={handleSubmit(doSubmit)}
        >
          {decision === "approve" && lastStep ? t("Confirm") : t("Next")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
