import { Box } from "@mui/material";
import FacilityLayout from "./FacilityLayout";

const _SettingsTabs = [
  "settings-facility",
  "settings-schedules",
  "settings-kiosks",
  "settings-in-person-visits",
  "settings-confidential-in-person-visits",
  "settings-voice-calls",
  "settings-video-calls",
  "settings-phone-calls",
  "settings-messages",
  "settings-confidential-video-calls",
  "settings-network",
  "settings-webinars",
] as const;
export type SettingsTab = (typeof _SettingsTabs)[number];

type Props = {
  children: React.ReactNode;
  selectedTab: SettingsTab;
};

export default function SettingsLayout({ children, selectedTab }: Props) {
  return (
    <FacilityLayout selectedTab={selectedTab}>
      <Box sx={{ display: "flex", height: 1 }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingBottom: 2,
            maxWidth: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    </FacilityLayout>
  );
}
