import { Button, Dialog, TextInput, useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import { appendItem } from "@src/api/client.ts";
import useApolloErrorHandler from "@src/lib/handleApolloError.ts";
import { PersonalRelationship } from "@src/lib/Relationship.ts";
import RelationshipInput from "@src/lib/RelationshipInput";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary.tsx";
import {
  hasMaxLength,
  isPhoneNumber,
  isRequired,
  isRequiredString,
  mergeRules,
} from "@src/lib/validate";
import { CreatePstnConnectionDocument } from "@src/pages/InmatePage/CreatePstnConnection.generated.ts";
import { MuiTelInput } from "mui-tel-input";
import { MuiTelInputCountry } from "mui-tel-input/dist/shared/constants/countries";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  inmateId: string;
  onClose: () => void;
};

type RelationshipFormData = {
  firstName: string;
  lastName?: string;
  pstnNumber: string;
  relationship: string;
  relationshipDetails: string;
};

export default function NewPhoneContactDialog({ onClose, inmateId }: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();
  const { facility } = useGuaranteedFacilityContext();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<RelationshipFormData>({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      pstnNumber: "",
      relationship: "",
      relationshipDetails: "",
    },
  });

  const [createPstnConnection] = useMutation(CreatePstnConnectionDocument, {
    onError: handleApolloError,
    onCompleted: () => {
      snackbarContext.alert("success", "Phone contact created successfully");
      onClose();
    },
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: cache.identify({ __typename: "Inmate", id: inmateId }),
        fields: {
          connections: appendItem(data.createPstnConnection.connection),
        },
      });
    },
  });

  const selectedRelationship = watch("relationship") || "";

  return (
    <Dialog fullWidth title={t("New phone contact")} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2}>
          <TextInput
            control={control}
            name="firstName"
            label={t("First name (legal)")}
            rules={isRequired(t("Please a valid first name."))}
          />
          <TextInput
            control={control}
            name="lastName"
            label={t("Last name (legal)")}
          />
          <Controller
            control={control}
            name="pstnNumber"
            render={({ field, fieldState }) => (
              <MuiTelInput
                sx={{ mt: 2, width: 1 }}
                defaultCountry={
                  (facility.country || "US") as MuiTelInputCountry
                }
                label={t("Phone number")}
                placeholder={"+1 555 555 5555"}
                required={true}
                error={!!(fieldState.error || fieldState.invalid)}
                helperText={
                  fieldState.error || fieldState.invalid
                    ? t("Please enter a valid phone number.")
                    : null
                }
                {...field}
              />
            )}
            rules={mergeRules(
              isRequired(t("Please enter a valid phone number.")),
              isPhoneNumber(),
            )}
          />
          <RelationshipInput
            name="relationship"
            control={control}
            label={t("Relationship type")}
          />
          {selectedRelationship === PersonalRelationship.Other.toString() && (
            <TextInput
              control={control}
              name="relationshipDetails"
              label={t("Relationship type")}
              rules={mergeRules(
                isRequiredString(t("Please enter a relationship type.")),
                hasMaxLength(
                  30,
                  t(
                    "Relationship type can't be longer than {{number}} characters.",
                    { number: 30 },
                  ),
                ),
              )}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(async (data) => {
            await createPstnConnection({
              variables: {
                input: {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  phone: data.pstnNumber,
                  inmateId,
                  relationship:
                    data.relationship === PersonalRelationship.Other.toString()
                      ? data.relationshipDetails.trim()
                      : data.relationship,
                },
              },
            });
          })}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
