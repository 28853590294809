import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateKioskMutationVariables = Types.Exact<{
  input: Types.UpdateKioskInput;
}>;


export type UpdateKioskMutation = { __typename: 'Mutation', updateKiosk: { __typename: 'UpdateKioskResponse', kiosk: { __typename: 'Kiosk', id: Types.Scalars['ID']['input'], name: string, enabled: boolean, capacity: number, meetingType: Types.MeetingType | null } } };


export const UpdateKioskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateKiosk"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateKioskInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateKiosk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"kiosk"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"KioskFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"KioskFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Kiosk"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"capacity"}},{"kind":"Field","name":{"kind":"Name","value":"meetingType"}}]}}]} as unknown as DocumentNode<UpdateKioskMutation, UpdateKioskMutationVariables>;