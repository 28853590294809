import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Box, DialogContent, Link, Stack } from "@mui/material";
import {
  Inmate,
  Interval,
  Meeting,
  TranscriptSearchResult,
  Visitor,
} from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import InmateLink from "@src/lib/InmateLink";
import PageLoader from "@src/lib/PageLoader";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useBoolean from "@src/lib/useBoolean";
import VisitorLink from "@src/lib/VisitorLink";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TranscriptPreview, { TranscriptSegment } from "./TranscriptPreview";

interface Props {
  transcripts: (Pick<
    TranscriptSearchResult,
    "content" | "contentWithQueryMatch"
  > & {
    meeting: Pick<Meeting, "id"> & {
      interval: Pick<Interval, "startAt">;
      inmates: Pick<Inmate, "id" | "fullName" | "inmateIdentification">[];
      visitors: Pick<Visitor, "id" | "fullName">[];
    };
  })[];
  loading?: boolean;
}

export default function TranscriptTable({ transcripts, loading }: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const [selectedTranscript, setSelectedTranscript] = useState<string>();
  const fullTranscriptDialog = useBoolean();
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: transcripts,
    resetOnChange: [transcripts],
  });

  return loading ? (
    <Box p={3}>
      <PageLoader />
    </Box>
  ) : (
    <Box p={3}>
      {fullTranscriptDialog.current && selectedTranscript && (
        <Dialog
          title={t("Call transcript")}
          onClose={fullTranscriptDialog.disable}
        >
          <DialogContent>
            <Stack spacing={2}>
              {selectedTranscript.split("\n\n").map((segment, idx) => (
                <TranscriptSegment
                  key={`${segment}_${idx}`}
                  segment={segment}
                />
              ))}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      {transcripts.length > 0 && (
        <TableContainer>
          <Table aria-label={t("Transcripts table")}>
            <TableHead>
              <TableRow>
                <TableCell>{t("Date")}</TableCell>
                <TableCell>{t("Start time")}</TableCell>
                <TableCell>{t("Residents")}</TableCell>
                <TableCell>{t("Visitors")}</TableCell>
                <TableCell>{t("Transcript preview")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map(
                ({ content, contentWithQueryMatch, meeting }) => (
                  <TableRow key={meeting.id}>
                    <TableCell>
                      {formatDate(meeting.interval.startAt, "date")}
                    </TableCell>
                    <TableCell>
                      {formatDate(meeting.interval.startAt, "time")}
                    </TableCell>
                    <TableCell>
                      {meeting.inmates.map((inmate) => (
                        <InmateLink key={inmate.id} inmate={inmate} />
                      ))}
                    </TableCell>
                    <TableCell>
                      {meeting.visitors.map((visitor) => (
                        <VisitorLink key={visitor.id} visitor={visitor} />
                      ))}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ maxHeight: 100, overflow: "hidden", mb: 1 }}>
                        <TranscriptPreview
                          segments={
                            contentWithQueryMatch
                              ? contentWithQueryMatch.split("\n\n")
                              : content
                                ? content.split("\n\n")
                                : []
                          }
                        />
                      </Box>
                      <Link
                        onClick={() => {
                          if (contentWithQueryMatch) {
                            setSelectedTranscript(contentWithQueryMatch);
                            fullTranscriptDialog.enable();
                          } else if (content) {
                            setSelectedTranscript(content);
                            fullTranscriptDialog.enable();
                          }
                        }}
                      >
                        {t("Read more")}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => navigate(`/records/${meeting.id}`)}
                      >
                        {t("See records")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        totalCount={transcripts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
      {transcripts.length === 0 && (
        <Alert severity="warning">{t("No results found.")}</Alert>
      )}
    </Box>
  );
}
