import { Button } from "@ameelio/ui";
import { Inmate } from "@src/api/graphql";
import { VisitorFragmentFragment } from "@src/graphql/fragments/VisitorFragment.generated";
import { GetConnectionMessagesQuery } from "@src/graphql/GetConnectionMessages.generated";
import { messagesToCsv } from "@src/lib/Message";
import { toCSVBlob } from "@src/lib/toCSV";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

type Message =
  GetConnectionMessagesQuery["connection"]["messages"]["edges"][0]["node"];

interface Props {
  messages: Message[];
  inmate: Pick<Inmate, "fullName" | "inmateIdentification">;
  visitor: VisitorFragmentFragment;
  startDate: number;
  endDate: number;
}

const MessagesCSVExport = ({
  messages,
  inmate,
  visitor,
  startDate,
  endDate,
}: Props) => {
  const fileNameStart = `${inmate.fullName} ${inmate.inmateIdentification} <> ${visitor.fullName} ${visitor.email}`;

  const firstDate = format(startDate, "yyyy-MM-dd");
  const lastDate = format(endDate, "yyyy-MM-dd");
  const filename = `${fileNameStart} [${firstDate} - ${lastDate}].csv`;

  const { t } = useTranslation();

  const generateAndSave = () => {
    const blob = toCSVBlob(messagesToCsv(messages));
    saveAs(blob, `${filename}.csv`);
  };

  return (
    <Button variant="contained" onClick={generateAndSave}>
      {t("Export")}
    </Button>
  );
};

export default MessagesCSVExport;
