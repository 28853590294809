import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdatePstnNumberMutationVariables = Types.Exact<{
  input: Types.UpdateVisitorPstnNumberInput;
}>;


export type UpdatePstnNumberMutation = { __typename: 'Mutation', updateVisitorPstnNumber: { __typename: 'UpdateVisitorPstnNumberResponse', visitor: { __typename: 'Visitor', id: Types.Scalars['ID']['input'], phone: string | null, pstnNumber: string | null } } };


export const UpdatePstnNumberDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePstnNumber"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateVisitorPstnNumberInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateVisitorPstnNumber"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"pstnNumber"}}]}}]}}]}}]} as unknown as DocumentNode<UpdatePstnNumberMutation, UpdatePstnNumberMutationVariables>;