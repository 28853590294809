import Joi from "joi";

/**
 * uses a Joi schema to strip any fields that do not validate.
 * only works for simple object schemas. does not support
 * nested objects.
 *
 * in more complex situations, use [any.failover()](https://joi.dev/api/?v=17.13.3#anyfailovervalue)
 */
export default function stripInvalid<T extends Record<string, unknown>>(
  schema: Joi.ObjectSchema<T>,
  obj: unknown,
): T {
  const result = schema.validate(obj, {
    stripUnknown: true,
    abortEarly: false,
  });
  if (!result.error) return result.value;

  const invalidFields = result.error.details.map(
    (detail) => detail.context?.key,
  );
  const cleaned = Object.fromEntries(
    // Joi's type appears to be wrong. `value` is in fact returned with `error`.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    Object.entries(result.value || {}).filter(
      ([key]) => !invalidFields.includes(key),
    ),
  );
  return cleaned as T;
}
