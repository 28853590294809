import { FacilityFeature } from "@src/api/graphql";

export default function hasScheduling(features: FacilityFeature[]) {
  return [
    FacilityFeature.InPersonVisit,
    FacilityFeature.VideoCall,
    FacilityFeature.Webinar,
    FacilityFeature.ConfidentialInPersonVisit,
    FacilityFeature.ConfidentialVideoCall,
  ].some((feature) => features.includes(feature));
}
