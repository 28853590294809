import { Button, useSnackbarContext } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import {
  Connection,
  ConnectionStatus,
  Entitlement,
  Facility,
  Inmate,
  SystemUserStatus,
  Visitor,
} from "@src/api/graphql";
import { UpdateConnectionStatusDocument } from "@src/graphql/UpdateConnectionStatus.generated";
import Drawer from "@src/lib/Drawer";
import facilityServiceFeature from "@src/lib/facilityServiceFeature";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useBoolean from "@src/lib/useBoolean";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  labelConnectionStatus,
  removeCachedPendingConnectionRequest,
} from "../../Connection";
import ConnectionStatusTag from "../../ConnectionStatusTag";
import { DetailsStackItem } from "../../DetailsStack";
import InmateContactCard from "../../InmateContactCard";
import OrganizationCard from "../../OrganizationCard";
import SystemRelationshipStatusTag from "../../SystemRelationshipStatusTag";
import { formatDate } from "../../Time";
import { SectionTitle } from "../../typography";
import VisitorContactCard from "../../VisitorContactCard";
import AddRestrictionDialog, {
  RestrictableServices,
} from "./AddRestrictionDialog";
import ConnectionRestrictionCard from "./ConnectionRestrictionCard";
import DisableConnectionModal from "./DisableConnectionModal";
import { GetConnectionDetailsDocument } from "./GetConnectionDetails.generated";

interface Props {
  isOpen: boolean;
  connectionId: string;
  onClose: () => void;
}

interface DisableButtonProps {
  connection: Pick<Connection, "id" | "relationship" | "status"> & {
    inmate: Pick<Inmate, "fullName">;
  } & { visitor: Pick<Visitor, "fullName"> };
  facility: Pick<Facility, "publicId" | "visitationFormLink">;
}

function DisableButton({ connection, facility }: DisableButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();
  const [updateConnectionStatusMutation] = useMutation(
    UpdateConnectionStatusDocument,
    {
      update: removeCachedPendingConnectionRequest,
      onCompleted: (data) => {
        snackbarContext.alert(
          "success",
          t("Contact was successfully {{status}}", {
            status: labelConnectionStatus(
              data.updateConnectionStatus.connection.status,
            ),
          }),
        );
      },
      onError: handleApolloError,
    },
  );

  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );

  return (
    <>
      {showModal && (
        <DisableConnectionModal
          onSubmit={(feedback) =>
            updateConnectionStatusMutation({
              variables: {
                input: {
                  connectionId: connection.id,
                  status: ConnectionStatus.Rejected,
                  statusDetails: feedback,
                },
              },
            })
          }
          onClose={() => setShowModal(false)}
          connection={connection}
          facility={facility}
        />
      )}
      <Button
        onClick={() => setShowModal(true)}
        color="error"
        variant="outlined"
        disabled={!canManageVisitorsAndMeetings}
      >
        {t("Remove approval")}
      </Button>
    </>
  );
}

export default function ConnectionDetailDrawer({
  isOpen,
  connectionId,
  onClose,
}: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );

  const { error, data, loading } = useQuery(GetConnectionDetailsDocument, {
    variables: { id: connectionId || "", systemId: facility.system.id },
  });

  if (error) throw new Error(error.message);

  const addRestrictionDialog = useBoolean();

  if (loading || !data) {
    // NOTE: currently we do not display a loader because this component
    // is embedded in the page even while !isOpen, all so it can slide.
    return <div />;
  }

  const { connection } = data;
  const { inmate, organizationMembership } = connection;

  const activeRestrictions = data.connection.restrictions.filter(
    (r) => r.isActive,
  );
  const restrictableServices = RestrictableServices.filter((s) =>
    facility.features.includes(facilityServiceFeature(s)),
  ).filter((s) => !activeRestrictions.some((r) => r.service === s));

  return (
    <Drawer open={isOpen} onToggle={onClose} title={t("Contact details")}>
      <Stack spacing={1.5}>
        <VisitorContactCard connection={connection} />

        {organizationMembership && (
          <OrganizationCard
            organization={organizationMembership.organization}
          />
        )}

        <InmateContactCard
          inmate={inmate}
          restrictions={connection.restrictions}
        />

        <DetailsStackItem label={t("Status")}>
          <ConnectionStatusTag status={connection.status} />
        </DetailsStackItem>
        <DetailsStackItem
          label={t("Status changed at")}
          value={formatDate(new Date(connection.statusChangedAt), "dateyear")}
        />
        {connection.status === ConnectionStatus.Rejected && (
          <DetailsStackItem
            label={t("Reason")}
            value={`${connection.statusDetails || t("None")}`}
          />
        )}
        {connection.visitor.systemRelationship && (
          <>
            <DetailsStackItem label={t("ID status")}>
              <SystemRelationshipStatusTag
                status={connection.visitor.systemRelationship.status}
              />
            </DetailsStackItem>
            {connection.visitor.systemRelationship.status ===
              SystemUserStatus.Rejected && (
              <DetailsStackItem
                label={t("Reason")}
                value={
                  connection.visitor.systemRelationship.statusDetails || ""
                }
              />
            )}
          </>
        )}
      </Stack>

      {connection.status === ConnectionStatus.Active && (
        <Box mt={3} mb={2}>
          <Box display="flex" justifyContent="space-between">
            <SectionTitle>{t("Restrictions")}</SectionTitle>
            <Button
              disabled={
                restrictableServices.length === 0 ||
                !canManageVisitorsAndMeetings
              }
              disabledReason={
                !canManageVisitorsAndMeetings
                  ? t("You are not allowed to manage service restrictions.")
                  : t("There are no more services that can be restricted.")
              }
              onClick={addRestrictionDialog.enable}
              variant="contained"
            >
              {t("Add")}
            </Button>
          </Box>
          {activeRestrictions.length === 0 ? (
            <Typography my={2} variant="body1" color="text.secondary">
              {t("None")}
            </Typography>
          ) : (
            <Stack spacing={2} my={2}>
              {activeRestrictions.map((r) => (
                <ConnectionRestrictionCard key={r.id} restriction={r} />
              ))}
            </Stack>
          )}
        </Box>
      )}

      {connection.status === ConnectionStatus.Active && (
        <Box
          mt={4}
          pt={2}
          borderTop={1}
          borderColor="divider"
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <DisableButton connection={connection} facility={facility} />
        </Box>
      )}

      {addRestrictionDialog.current && (
        <AddRestrictionDialog
          restrictableServices={restrictableServices}
          connectionIds={[connection.id]}
          inmateId={inmate.id}
          onClose={addRestrictionDialog.disable}
        />
      )}
    </Drawer>
  );
}
