import { Role, useConnectCall } from "@ameelio/connect-call-client";
import { FluidGrid, FluidGridItem } from "@ameelio/ui";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { MeetingType } from "@src/api/graphql";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import Drawer from "@src/lib/Drawer";
import Header from "@src/lib/Header";
import { Audio, UserLabel, Video } from "@src/lib/LiveCall";
import VideoCall from "@src/lib/LiveCall/VideoCall";
import { meetingTypeTitle } from "@src/lib/meeting";
import MessageDisplay from "@src/lib/MessageDisplay";
import Result from "@src/lib/Result";
import { isMonitored } from "@src/lib/securityFeatureUtils.ts";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useLiveCalls from "@src/lib/useLiveCalls";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type StreamData = {
  id: string;
  videoStream?: MediaStream;
  audioStream?: MediaStream;
  label: string;
  muted: boolean;
};

export default function LiveWebinarPage() {
  const { meetingId } = useParams<{ meetingId: string }>();
  if (!meetingId) throw new Error("missing meetingId param");
  const { t } = useTranslation();

  const currentStaff = useCurrentStaff();
  const { facility } = useGuaranteedFacilityContext();
  const liveCalls = useLiveCalls({
    facility,
    meetingTypes: [MeetingType.Webinar],
  });
  const webinar = liveCalls.find((c) => c.id === meetingId);
  const call = webinar?.call;

  // guard against changes to the call data by saving the first
  // copy of it. this fixes a loop where refreshing the list of
  // live calls generates new auth tokens, resulting in a reconnect.
  const [callCache] = useState({
    id: call?.id || "",
    url: call?.url || "",
    token: call?.token || "",
  });

  const { messages, peers } = useConnectCall({
    call: {
      id: callCache.id,
      url: callCache.url,
      token: callCache.token,
    },
    user: { id: currentStaff.id },
  });

  const [isMuted, setIsMuted] = useState(true);

  const participantNamesById = useMemo(
    () =>
      webinar
        ? Object.fromEntries(
            [...webinar.visitors, ...webinar.inmates].map((p) => [
              p.id,
              p.fullName,
            ]),
          )
        : {},
    [webinar],
  );

  const [chatCollapsed, setChatCollapsed] = useState(false);
  const [pinnedStream, setPinnedStream] = useState<StreamData>();

  // generate a collection of both screen and camera streams
  const streamDatas: StreamData[] = useMemo(() => {
    if (pinnedStream) return [pinnedStream];

    const sortedPeers = Object.values(peers)
      .filter((p) => p.user.role !== Role.monitor)
      .sort((a, b) => a.peerId.localeCompare(b.peerId));
    return [
      // screen shares are always placed first, even if the presenter isn't next
      ...sortedPeers
        .filter(
          (p) => p.consumers.screenshare && !p.consumers.screenshare.paused,
        )
        .map((p) => ({
          id: p.peerId,
          videoStream: p.consumers.screenshare?.stream,
          label: `${participantNamesById[p.user.id]}'s Screen`,
          muted: true,
        })),
      ...sortedPeers.map((p) => ({
        id: p.peerId,
        videoStream: p.consumers.video?.stream,
        audioStream: p.consumers.audio?.stream,
        label: participantNamesById[p.user.id],
        muted: p.consumers.audio?.paused !== false,
      })),
    ];
  }, [pinnedStream, peers, participantNamesById]);

  if (!webinar) return <Result variant="404" title={t("Expired link")} />;
  if (!call) return <Result variant="404" title={t("Call not ready")} />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header
        title={webinar.title || meetingTypeTitle([webinar.meetingType])}
        breadcrumb={
          <Breadcrumbs
            paths={[
              { path: "/live/webinars", label: t("Education webinars") },
              {
                path: `/live/webinars/${webinar.id}`,
                label: webinar.title || "",
              },
            ]}
          />
        }
        showBack
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <VideoCall
          onToggleMute={() => setIsMuted(!isMuted)}
          isMuted={isMuted}
          showSendAlert={isMonitored(webinar.security)}
          onToggleChat={() => setChatCollapsed(!chatCollapsed)}
          chatCollapsed={chatCollapsed}
          scheduledEnd={webinar.interval.endAt}
          sx={{
            flex: 1,
            display: "flex",
            overflow: "hidden",
            m: 3,
            pt: 4,
          }}
        >
          <FluidGrid size={streamDatas.length} gap={2}>
            {streamDatas.map((data) => (
              <FluidGridItem
                key={data.id}
                sx={{
                  backgroundColor: grey[900],
                  borderRadius: 1,
                  position: "relative",
                }}
              >
                <Video
                  srcObject={data.videoStream}
                  autoPlay
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
                <Audio srcObject={data.audioStream} autoPlay />
                <UserLabel
                  name={data.label}
                  isMuted={data.muted}
                  onTogglePin={() =>
                    data.videoStream?.id === pinnedStream?.videoStream?.id
                      ? setPinnedStream(undefined)
                      : setPinnedStream(data)
                  }
                />
              </FluidGridItem>
            ))}
          </FluidGrid>
        </VideoCall>
        <Drawer
          collapsible={true}
          open={!chatCollapsed}
          onToggle={(value) => setChatCollapsed(!value)}
          title={t("Chat")}
          size={320}
        >
          {!messages.length && (
            <Typography variant="body1" color="text.secondary">
              {t("No messages yet")}
            </Typography>
          )}

          {!chatCollapsed && (
            <Stack gap={4}>
              {messages.map((message, idx) => {
                const sender =
                  webinar.inmates.find((i) => i.id === message.user.id) ||
                  webinar.visitors.find((v) => v.id === message.user.id);
                return (
                  <MessageDisplay
                    key={idx}
                    message={{
                      callId: webinar.id,
                      senderType: sender?.__typename || "Staff",
                      senderName:
                        message.user.role === Role.monitor
                          ? t("Facility Staff")
                          : participantNamesById[message.user.id],
                      contents: message.contents,
                      createdAt: message.timestamp.getTime(),
                    }}
                  />
                );
              })}
            </Stack>
          )}
        </Drawer>
      </Box>
    </Box>
  );
}
