import { useQuery } from "@apollo/client";
import { Card, Divider, Stack } from "@mui/material";
import { Entitlement, FacilityFeature } from "@src/api/graphql";
import { GetFacilityDocument } from "@src/graphql/GetFacility.generated";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import { WEEKDAYS, WEEKDAY_NAMES } from "@src/lib/constants";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import NotFoundSplashScreen from "@src/lib/NotFoundSplashScreen";
import SchedulingWindowSettingField from "@src/lib/SchedulingWindowSettingField";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import {
  BooleanSettingField,
  NumberSettingField,
  SelectSettingField,
} from "@src/lib/SettingField";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import { useTranslation } from "react-i18next";

export default function NewInPersonVisitsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const handleApolloError = useApolloErrorHandler();

  const { data } = useQuery(GetFacilitySchedulesDocument, {
    onError: handleApolloError,
    variables: { facilityId: facility.id },
  });

  const { updateProperty, updateProperties } = useUpdateFacilityProperty();

  const { data: facilityData, error } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id },
  });

  if (error) throw error;
  if (!data || !facilityData) return <SettingsSkeleton />;

  const { facility: loadedFacility } = facilityData;

  if (
    !loadedFacility.features.some((f) =>
      [
        FacilityFeature.InPersonVisit,
        FacilityFeature.ConfidentialInPersonVisit,
      ].includes(f),
    )
  )
    return <NotFoundSplashScreen />;

  return (
    <>
      <Header
        title={t("In-person visits")}
        subtitle={t(
          "Approved contacts may request in-person visits according to a defined schedule.",
        )}
      />
      <Stack spacing={2} p={2}>
        {loadedFacility.features.includes(FacilityFeature.InPersonVisit) && (
          <Card variant="outlined" sx={{ borderRadius: "8px" }}>
            <Stack padding={3} spacing={1.5}>
              <SectionTitle>{t("Monitored in-person visits")}</SectionTitle>
              <SchedulingWindowSettingField
                value={{
                  cutoffDays: loadedFacility.personalVisitCutoff,
                  windowWeeks: loadedFacility.personalVisitWindow,
                }}
                onChange={(data) =>
                  updateProperties({
                    personalVisitCutoff: data.cutoffDays,
                    personalVisitWindow: data.windowWeeks,
                  })
                }
              />
              <Divider />
              <BooleanSettingField
                label={t("Visits require reviews")}
                description={t(
                  "Visits will not be scheduled unless approved by staff.",
                )}
                value={loadedFacility.visitNeedsApproval}
                onChange={(value) =>
                  updateProperty("visitNeedsApproval", value)
                }
                canEdit={canEdit}
              />
              <Divider />
              <NumberSettingField
                label={t("Default weekly quota")}
                description={t(
                  "The default number of in-person visits allowed each week. This can be customized per resident during roster upload.",
                )}
                value={loadedFacility.defaultVisitQuota}
                onChange={(value) => updateProperty("defaultVisitQuota", value)}
                validate={{
                  min: (v) => v >= 0,
                  max: (v) => v < 2147483647,
                }}
                errorMessages={{
                  min: t("Cannot be negative."),
                  max: t("This number is too large."),
                }}
                canEdit={canEdit}
              />
              <Divider />
              <SelectSettingField
                label={t("Start of week")}
                description={t(
                  "The day when the quota resets each week to allow more visits.",
                )}
                value={loadedFacility.visitQuotaResetDay}
                onChange={(value) =>
                  updateProperty("visitQuotaResetDay", value)
                }
                render={(value) => WEEKDAY_NAMES[value]}
                selectOptions={WEEKDAYS.map((day) => ({
                  value: day as number,
                  name: WEEKDAY_NAMES[day],
                }))}
                canEdit={canEdit}
              />
            </Stack>
          </Card>
        )}
        {loadedFacility.features.includes(
          FacilityFeature.ConfidentialInPersonVisit,
        ) && (
          <Card variant="outlined" sx={{ borderRadius: "8px" }}>
            <Stack padding={3} spacing={1.5}>
              <SectionTitle>{t("Confidential in-person visits")}</SectionTitle>
              <SchedulingWindowSettingField
                value={{
                  cutoffDays: loadedFacility.providerVisitCutoff,
                  windowWeeks: loadedFacility.providerVisitWindow,
                }}
                onChange={(data) =>
                  updateProperties({
                    providerVisitCutoff: data.cutoffDays,
                    providerVisitWindow: data.windowWeeks,
                  })
                }
              />
            </Stack>
          </Card>
        )}
      </Stack>
    </>
  );
}
