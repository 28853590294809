import { SecurityFeature } from "@src/api/graphql.ts";
import { t } from "i18next";
import { capitalize } from "lodash";

/*
 * Confidential, Monitored, Hidden are three different possibilities that can be mapped to the SecurityFeature enum.
 * Specifically, where before we were checking for:
 * privacyLevel === unmonitored -> Confidential
 * privacyLevel === monitored -> Monitored
 * privacyLevel === hidden -> Hidden
 * privacyLevel !== monitored -> !Monitored (could be either Confidential or Hidden)
 */

export const isConfidential = (features: SecurityFeature[]) => {
  const logged = features.includes(SecurityFeature.Logged);
  const monitored = features.includes(SecurityFeature.Monitoring);
  const recorded = features.includes(SecurityFeature.Recording);
  return logged && !(monitored || recorded);
};

export const isMonitored = (features: SecurityFeature[]) => {
  return features.includes(SecurityFeature.Monitoring);
};

const securityFeatureToHumanReadable = (Feature: SecurityFeature) => {
  const map: Record<SecurityFeature, string> = {
    [SecurityFeature.Logged]: t("logged"),
    [SecurityFeature.Monitoring]: t("monitoring"),
    [SecurityFeature.Pictures]: t("pictures"),
    [SecurityFeature.Recording]: t("recording"),
  };
  return map[Feature];
};

export function securityFeaturesList(features: SecurityFeature[]) {
  return (
    features.map(securityFeatureToHumanReadable).map(capitalize).join(", ") ||
    "-"
  );
}
