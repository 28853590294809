import { useQuery } from "@apollo/client";
import { Card, Divider, Stack, Typography } from "@mui/material";
import { Entitlement, FacilityFeature } from "@src/api/graphql";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import { WEEKDAYS, WEEKDAY_NAMES } from "@src/lib/constants";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import NotFoundSplashScreen from "@src/lib/NotFoundSplashScreen";
import SchedulingWindowSettingField from "@src/lib/SchedulingWindowSettingField";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import {
  BooleanSettingField,
  NumberSettingField,
  SelectSettingField,
} from "@src/lib/SettingField";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import { useTranslation } from "react-i18next";
import { GetFacilityDocument } from "../../graphql/GetFacility.generated";

export default function NewVideoCallsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();

  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const handleApolloError = useApolloErrorHandler();
  const { data } = useQuery(GetFacilitySchedulesDocument, {
    onError: handleApolloError,
    variables: { facilityId: facility.id },
  });

  const { updateProperty, updateProperties } = useUpdateFacilityProperty();

  const { data: facilityData, error } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id },
  });

  if (error) throw error;
  if (!data || !facilityData) return <SettingsSkeleton />;

  const { facility: loadedFacility } = facilityData;

  if (
    !loadedFacility.features.some((f) =>
      [
        FacilityFeature.VideoCall,
        FacilityFeature.ConfidentialVideoCall,
        FacilityFeature.Webinar,
      ].includes(f),
    )
  )
    return <NotFoundSplashScreen />;

  return (
    <>
      <Header
        title={t("Video calls")}
        subtitle={t(
          "Approved visitors may request video calls according to a defined schedule.",
        )}
      />
      <Stack spacing={2} p={2}>
        {facility.features.includes(FacilityFeature.VideoCall) && (
          <Card variant="outlined" sx={{ borderRadius: "8px" }}>
            <Stack padding={3} spacing={1.5}>
              <SectionTitle>{t("Monitored video calls")}</SectionTitle>
              <SchedulingWindowSettingField
                value={{
                  cutoffDays: loadedFacility.personalVideoCutoff,
                  windowWeeks: loadedFacility.personalVideoWindow,
                }}
                onChange={(data) =>
                  updateProperties({
                    personalVideoCutoff: data.cutoffDays,
                    personalVideoWindow: data.windowWeeks,
                  })
                }
              />
              <Divider />
              <BooleanSettingField
                label={t("Calls require reviews")}
                description={t(
                  "Video calls will not be scheduled unless approved by staff.",
                )}
                value={loadedFacility.videoNeedsApproval}
                onChange={(value) =>
                  updateProperty("videoNeedsApproval", value)
                }
                canEdit={canEdit}
              />
              <Divider />
              <BooleanSettingField
                label={t("Calls require monitors")}
                description={t(
                  "Video calls will not start unless a staff member is logged on to monitor it. This can be customized per resident during roster upload.",
                )}
                value={loadedFacility.defaultNeedsMonitor}
                onChange={(value) =>
                  updateProperty("defaultNeedsMonitor", value)
                }
                canEdit={canEdit}
              />
              <Divider />
              <NumberSettingField
                label={t("Default weekly quota")}
                description={t(
                  "The default number of video calls allowed each week. This can be customized per resident during roster upload.",
                )}
                value={loadedFacility.defaultCallQuota}
                onChange={(value) => updateProperty("defaultCallQuota", value)}
                validate={{
                  min: (v) => v >= 0,
                  max: (v) => v < 2147483647,
                }}
                errorMessages={{
                  min: t("Cannot be negative."),
                  max: t("This number is too large."),
                }}
                canEdit={canEdit}
              />
              <Divider />
              <SelectSettingField
                label={t("Start of week")}
                description={t(
                  "The day of the week when the quota resets and new video calls may be scheduled.",
                )}
                value={loadedFacility.videoQuotaResetDay}
                render={(value) => WEEKDAY_NAMES[value]}
                onChange={(value) =>
                  updateProperty("videoQuotaResetDay", value)
                }
                selectOptions={WEEKDAYS.map((day) => ({
                  value: day,
                  name: WEEKDAY_NAMES[day],
                }))}
                canEdit={canEdit}
              />
            </Stack>
          </Card>
        )}
        {facility.features.includes(FacilityFeature.ConfidentialVideoCall) && (
          <Card variant="outlined" sx={{ borderRadius: "8px" }}>
            <Stack padding={3} spacing={1.5}>
              <SectionTitle>{t("Confidential video calls")}</SectionTitle>
              <SchedulingWindowSettingField
                value={{
                  cutoffDays: loadedFacility.providerVideoCutoff,
                  windowWeeks: loadedFacility.providerVideoWindow,
                }}
                onChange={(data) =>
                  updateProperties({
                    providerVideoCutoff: data.cutoffDays,
                    providerVideoWindow: data.windowWeeks,
                  })
                }
              />
            </Stack>
          </Card>
        )}
        {facility.features.includes(FacilityFeature.Webinar) && (
          <Card variant="outlined" sx={{ borderRadius: "8px" }}>
            <Stack padding={3} spacing={1.5}>
              <SectionTitle>{t("Webinars")}</SectionTitle>
              <Typography variant="subtitle1" component="label">
                {t("Webinars use the monitored video calls scheduling window.")}
              </Typography>
            </Stack>
          </Card>
        )}
      </Stack>
    </>
  );
}
