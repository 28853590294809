import { Button } from "@ameelio/ui";
import { Box, Card, Stack } from "@mui/material";
import { Entitlement, MeetingType, PrivacyLevel } from "@src/api/graphql";
import { GetFacilitySchedulesQuery } from "@src/graphql/GetFacilitySchedules.generated";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConditionalTooltip from "../ConditionalTooltip";
import AddEditScheduleModal from "./AddEditScheduleModal";
import ScheduleCard from "./ScheduleCard";

type Schedule = GetFacilitySchedulesQuery["facility"]["schedules"][number];

type Props = {
  title: string;
  schedules: Schedule[];
  meetingType: MeetingType;
  privacyLevel: PrivacyLevel;
};

export default function ScheduleTable({
  title,
  schedules,
  meetingType,
  privacyLevel,
}: Props) {
  const [isAddingSchedule, setIsAddingSchedule] = useState(false);
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  return (
    <>
      <Card variant="outlined" sx={{ padding: 3, borderRadius: "8px" }}>
        <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
          <Box>
            <SectionTitle>{title}</SectionTitle>
          </Box>
          <ConditionalTooltip
            title={t("You do not have permission to add schedules.")}
            active={!canEdit}
          >
            <Button
              onClick={() => {
                setIsAddingSchedule(true);
              }}
              variant="outlined"
              disabled={!canEdit}
            >
              {t("Add schedule")}
            </Button>
          </ConditionalTooltip>
        </Box>
        <Stack spacing={2}>
          {schedules.map((s) => (
            <ScheduleCard key={s.id} schedule={s} />
          ))}
        </Stack>
      </Card>

      {isAddingSchedule && (
        <AddEditScheduleModal
          meetingType={meetingType}
          privacyLevel={privacyLevel}
          onClose={() => setIsAddingSchedule(false)}
        />
      )}
    </>
  );
}
