import { LockOutlined } from "@ant-design/icons";
import Result from "@src/lib/Result";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  header?: ReactElement;
  title?: string;
  subtitle?: string;
};

export default function NotAllowed({ header, title, subtitle }: Props) {
  const { t } = useTranslation();
  return (
    <>
      {header && header}
      <Result
        icon={<LockOutlined />}
        title={title || t("Sorry, you are not able to access this page")}
        subTitle={
          subtitle || t("This page is only viewable by select staff users.")
        }
      />
    </>
  );
}
