// this works equally well for Array and NodeList
type Firstable<T> = {
  [key: number]: T;
};

/**
 * `first` is a utility type that is intended to replace the `arr[0]`
 * pattern with `first(arr)`. This is useful when you want to guard
 * against `undefined` values.
 */
export default function first<T>(arr: Firstable<T>): T | undefined {
  return arr[0];
}
