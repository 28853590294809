import { Media } from "@ameelio/ui";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  ConnectionRestriction,
  Correspondent,
  Connection as GqlConnection,
  Organization,
  OrganizationMembership,
} from "@src/api/graphql";
import Avatar from "@src/lib/Avatar";
import { relationshipToHumanReadable } from "@src/lib/Relationship.ts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConnectionDetailDrawer from "./ConnectionDetailDrawer";

export interface Props {
  connection: Connection;
  correspondent: Pick<Correspondent, "fullName">;
}

type Connection = Pick<GqlConnection, "id" | "status" | "relationship"> & {
  restrictions: Pick<ConnectionRestriction, "id" | "isActive">[];
  organizationMembership?:
    | (Pick<OrganizationMembership, "id"> & {
        organization: Pick<Organization, "name">;
      })
    | null;
};

export default function ConnectionItem({ connection, correspondent }: Props) {
  const [detailDrawerIsOpen, setDetailDrawerIsOpen] = useState(false);

  const { t } = useTranslation();

  const name = correspondent.fullName;
  const hasRestriction =
    connection.restrictions.filter((r) => r.isActive).length > 0;

  return (
    <>
      <Box
        onClick={() => setDetailDrawerIsOpen(true)}
        px={2}
        py={1.5}
        sx={{ cursor: "pointer", "&:hover": { backgroundColor: grey[200] } }}
      >
        <Media image={<Avatar fallback={name} />} gap={1}>
          <Typography variant="body1">{name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {connection.organizationMembership?.organization.name ||
              relationshipToHumanReadable(connection.relationship)}
          </Typography>
          {hasRestriction && (
            <Typography variant="caption" color="error.main">
              {" "}
              ({t("Restricted")})
            </Typography>
          )}
        </Media>
      </Box>
      <ConnectionDetailDrawer
        connectionId={connection.id}
        isOpen={detailDrawerIsOpen}
        onClose={() => setDetailDrawerIsOpen(false)}
      />
    </>
  );
}
