import { Box, Link, Stack, Typography } from "@mui/material";
import { Visitor } from "@src/api/graphql";
import { isMinor } from "@src/lib/Common";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";
import first from "./first";

export type MeetingVisitor = Pick<Visitor, "id" | "fullName" | "dateOfBirth">;

interface Props {
  visitors: MeetingVisitor[];
  combineMinorGuests?: boolean;
  unregisteredGuests: string[];
}

/**
 * VisitorList displays the complete list of visitors, including
 * the primary visitor and all registered or unregistered guests.
 */
export default function VisitorList({
  visitors,
  unregisteredGuests,
  combineMinorGuests,
}: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  const adultVisitors = visitors.filter((v) => !isMinor(v));
  const minorVisitors = visitors.filter((v) => isMinor(v));

  const primaryVisitor = first(adultVisitors);
  const otherAdults = adultVisitors.slice(1);
  const hasGuests =
    otherAdults.length || minorVisitors.length || unregisteredGuests.length;
  const hasMinorsOrUnregisteredGuests =
    minorVisitors.length || unregisteredGuests.length;

  return (
    <Stack>
      {primaryVisitor ? (
        <Stack
          key={primaryVisitor.id}
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <Link onClick={() => navigate(`/visitors/${primaryVisitor.id}`)}>
            {primaryVisitor.fullName}
          </Link>
        </Stack>
      ) : null}
      {hasGuests && (!combineMinorGuests || otherAdults.length) ? (
        <Typography variant="caption" sx={{ mt: 1 }}>
          {t("GUESTS")}
        </Typography>
      ) : null}
      {otherAdults.map((adult) => (
        <Box sx={{ whiteSpace: "nowrap" }} key={adult.id}>
          <Link onClick={() => navigate(`/visitors/${adult.id}`)}>
            {adult.fullName}
          </Link>
        </Box>
      ))}
      {combineMinorGuests && hasMinorsOrUnregisteredGuests ? (
        <Typography variant="caption" sx={{ mt: 1 }}>
          {t("MINORS")}
        </Typography>
      ) : null}
      {minorVisitors.map((m) => (
        <div key={m.id}>
          <Link onClick={() => navigate(`/visitors/${m.id}`)}>
            {m.fullName}
          </Link>
        </div>
      ))}
      {unregisteredGuests.map((guest, idx) => (
        <div key={idx}>{guest}</div>
      ))}
    </Stack>
  );
}
