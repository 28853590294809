import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Box } from "@mui/material";
import { FacilityFeature, Visitor } from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary.tsx";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";
import Avatar from "../../lib/Avatar";

interface Props {
  visitors: Pick<Visitor, "id" | "fullName" | "email" | "pstnNumber">[];
  loading?: boolean;
}

export default function VisitorTable({ visitors, loading }: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: visitors,
    resetOnChange: [visitors],
  });

  const shouldShowEmail = visitors.some((v) => v.email);
  const shouldShowPstnNumber =
    facility.features.find((f) => f === FacilityFeature.VoiceCall) &&
    visitors.some((v) => v.pstnNumber);

  return loading ? (
    <Box p={3}>
      <PageLoader />
    </Box>
  ) : (
    <Box p={3}>
      {visitors.length > 0 && (
        <TableContainer>
          <Table aria-label={t("Visitors table")}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t("Name")}</TableCell>
                {shouldShowEmail && <TableCell>{t("Email")}</TableCell>}
                {shouldShowPstnNumber && (
                  <TableCell>{t("Phone number")}</TableCell>
                )}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map(({ id, fullName, email, pstnNumber }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Avatar fallback={fullName} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {fullName}
                  </TableCell>
                  {shouldShowEmail && <TableCell>{email || "-"}</TableCell>}
                  {shouldShowPstnNumber && (
                    <TableCell>{pstnNumber || "-"}</TableCell>
                  )}
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/visitors/${id}`)}
                    >
                      {t("See details")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        totalCount={visitors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
      {visitors.length === 0 && (
        <Alert severity="warning">{t("No results found.")}</Alert>
      )}
    </Box>
  );
}
