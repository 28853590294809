import { Button, Dialog, useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import { Visitor } from "@src/api/graphql.ts";
import useApolloErrorHandler from "@src/lib/handleApolloError.ts";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary.tsx";
import { isPhoneNumber, isRequired, mergeRules } from "@src/lib/validate";
import { UpdatePstnNumberDocument } from "@src/pages/VisitorPage/UpdatePstnNumber.generated.ts";
import { MuiTelInput } from "mui-tel-input";
import { MuiTelInputCountry } from "mui-tel-input/dist/shared/constants/countries";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  visitor: Pick<Visitor, "pstnNumber"> & {
    systemRelationship?: {
      id: string;
    } | null;
  };
  onClose: () => void;
};

type FormData = {
  pstnNumber: string;
};

export default function UpdatePhoneNumberDialog({ onClose, visitor }: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();
  const { facility } = useGuaranteedFacilityContext();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      pstnNumber: visitor.pstnNumber || "",
    },
  });

  const [updatePstnNumber] = useMutation(UpdatePstnNumberDocument, {
    onError: handleApolloError,
    onCompleted: () => {
      snackbarContext.alert("success", "Phone number updated successfully");
      onClose();
    },
  });

  return (
    <Dialog fullWidth title={t("Update phone number")} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2}>
          <Controller
            control={control}
            name="pstnNumber"
            render={({ field, fieldState }) => (
              <MuiTelInput
                sx={{ mt: 2, width: 1 }}
                defaultCountry={
                  (facility.country || "US") as MuiTelInputCountry
                }
                label={t("Phone number")}
                placeholder={"+1 555 555 5555"}
                required={true}
                error={!!(fieldState.error || fieldState.invalid)}
                helperText={
                  fieldState.error || fieldState.invalid
                    ? t("Please enter a valid phone number.")
                    : null
                }
                {...field}
              />
            )}
            rules={mergeRules(
              isRequired(t("Please enter a valid phone number.")),
              isPhoneNumber(),
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(async (data) => {
            if (!visitor.systemRelationship?.id) {
              throw new Error("Trying to update without a system visitor Id");
            }
            await updatePstnNumber({
              variables: {
                input: {
                  systemVisitorId: visitor.systemRelationship.id,
                  pstnNumber: data.pstnNumber,
                },
              },
            });
          })}
        >
          {t("Update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
