import { Link } from "@mui/material";
import { Staff } from "@src/api/graphql";
import { useAppNavigate } from "@src/lib/useAppNavigate";

interface Props {
  staff: Pick<Staff, "id" | "fullName">;
}

export default function StaffLink({ staff }: Props) {
  const navigate = useAppNavigate();
  return (
    <Link onClick={() => navigate(`/system/staff/${staff.id}`)}>
      {staff.fullName}
    </Link>
  );
}
