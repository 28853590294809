const _features = [
  "TEST_CALLS",
  "LANGUAGE_SETTINGS",
  "DAILY_SCHEDULE",
  "SCHEDULES_PAGE",
] as const;
type Feature = (typeof _features)[number];

export default function featureEnabled(name: Feature): boolean {
  const feature = import.meta.env[`VITE_ENABLE_${name}`];
  // If there was a string value provided, it MUST be 'true' when lowered and trimmed
  return typeof feature === "string"
    ? feature.toLowerCase().trim() === "true"
    : !!feature;
}
