import * as Types from '../../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateFacilityApprovedPhoneMutationVariables = Types.Exact<{
  input: Types.CreateFacilityApprovedPhoneInput;
}>;


export type CreateFacilityApprovedPhoneMutation = { __typename: 'Mutation', createFacilityApprovedPhone: { __typename: 'CreateFacilityApprovedPhoneResponse', facilityApprovedPhone: { __typename: 'FacilityApprovedPhone', id: Types.Scalars['ID']['input'], number: string, name: string, description: string, active: boolean, security: Array<Types.SecurityFeature> } } };


export const CreateFacilityApprovedPhoneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFacilityApprovedPhone"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateFacilityApprovedPhoneInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFacilityApprovedPhone"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityApprovedPhone"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityApprovedPhoneFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FacilityApprovedPhoneFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FacilityApprovedPhone"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"number"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"security"}}]}}]} as unknown as DocumentNode<CreateFacilityApprovedPhoneMutation, CreateFacilityApprovedPhoneMutationVariables>;