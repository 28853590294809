import { t } from "i18next";

export default function statusDetailsLabel(details: string) {
  if (details === "call_idle")
    return t("Call disconnected due to network issues");
  if (details === "call_reached_max_duration")
    return t("Call reached maximum available duration");
  if (details === "call_reached_scheduled_end") return "–";
  if (details === "no_show") return "–";
  return details;
}
