import { Media } from "@ameelio/ui";
import {
  CheckOutlined,
  DescriptionOutlined,
  DoNotDisturbOutlined,
  ScheduleOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { Card, Link, Stack, Typography } from "@mui/material";
import { InstantReply, MessageStatus, MessageType } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";
import {
  getMessageSenderType,
  getMessageStatusColor,
  getMessageStatusLabel,
  Message,
} from "@src/lib/Message";
import { formatDate } from "@src/lib/Time";
import ReviewTooltip from "@src/pages/MessageReviewPage/MessageReviewPanel/ReviewTooltip";
import parse from "html-react-parser";

interface Props {
  message: Message;
}

export const makeBold = (item: string, keywords: string[]) => {
  const re = new RegExp(keywords.join("|"), "g");
  return item.replace(re, (keyword) => "<b>" + keyword + "</b>");
};

// adds support for parsing instant reply enum values
// into human-readable strings
const parseMessage = (message: string) => {
  if (message === InstantReply.CallBackFiveMinutes.toString())
    return i18n.t("Can you call back in 5 minutes?");
  if (message === InstantReply.CallBackNow.toString())
    return i18n.t("I'm free now, call me back.");
  if (message === InstantReply.CallBackTwoMinutes.toString())
    return i18n.t("Can you call back in 2 minutes?");
  if (message === InstantReply.CantTalkNow.toString())
    return i18n.t("Sorry can't talk now.");
  return message;
};

const getFilenameFromUrl = (url: string) => {
  const pathname = new URL(url).pathname;
  const index = pathname.lastIndexOf("/");
  return decodeURI(pathname.substring(index + 1)); // if index === -1 then index+1 will be 0
};

function MessagePreview({ message }: Props) {
  switch (message.type) {
    case MessageType.Document:
      return message.fileUrl ? (
        <Media image={<DescriptionOutlined />} gap={1}>
          <Link href={message.fileUrl} target={"_blank"} rel={"noreferrer"}>
            {getFilenameFromUrl(message.fileUrl)}
          </Link>
        </Media>
      ) : null;
    case MessageType.Photo:
      return message.fileUrl ? (
        <img
          src={message.fileUrl}
          alt={i18n.t("Something went wrong loading this image.")}
          style={{ maxWidth: "400px", maxHeight: "400px", width: "100%" }}
        />
      ) : null;
    case MessageType.Text:
      return null;
  }
}

function MessageStatusIcon({ status }: { status: MessageStatus }) {
  switch (status) {
    case MessageStatus.Queued:
      return (
        <ScheduleOutlined
          sx={{ fontSize: "inherit", color: getMessageStatusColor(status) }}
        />
      );
    case MessageStatus.PendingReview:
      return (
        <WarningAmberOutlined
          sx={{ fontSize: "inherit", color: getMessageStatusColor(status) }}
        />
      );
    case MessageStatus.Rejected:
      return (
        <DoNotDisturbOutlined
          sx={{ fontSize: "inherit", color: getMessageStatusColor(status) }}
        />
      );
    case MessageStatus.Approved:
      return (
        <CheckOutlined
          sx={{ fontSize: "inherit", color: getMessageStatusColor(status) }}
        />
      );
    case MessageStatus.SkippedReview:
    case MessageStatus.ProcessingFailed:
    default:
      return null;
  }
}

export default function MessageCard({ message }: Props) {
  const senderType = getMessageSenderType(message);

  const senderName =
    senderType === "inmate"
      ? message.connection.inmate.fullName
      : message.connection.visitor.fullName;

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        alignItems: senderType === "inmate" ? "" : "flex-end",
      }}
    >
      <Media gap={1} image={<MessageStatusIcon status={message.status} />}>
        <Typography
          variant="caption"
          color={getMessageStatusColor(message.status)}
        >
          {getMessageStatusLabel(message.status)}{" "}
          <ReviewTooltip message={message} />
        </Typography>
      </Media>
      <Stack
        direction={senderType === "inmate" ? "row" : "row-reverse"}
        spacing={1}
        alignItems="center"
      >
        <Card variant="outlined" sx={{ p: 2 }}>
          <Stack spacing={1}>
            <Typography variant="body1">
              {message.detectedKeywords.length > 0
                ? parse(
                    makeBold(
                      message.content,
                      message.detectedKeywords.map(
                        (detection) => detection.spelling,
                      ),
                    ),
                  )
                : parseMessage(message.content)}
            </Typography>
            <MessagePreview message={message} />
          </Stack>
        </Card>
      </Stack>
      <Typography variant="caption" color="text.secondary">
        {senderName}
        {" • "}
        {formatDate(message.createdAt, "datetime")}
      </Typography>
      <Stack direction="row" spacing={1}></Stack>
    </Stack>
  );
}
