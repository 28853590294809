import { SelectInputBase } from "@ameelio/ui";
import { Box } from "@mui/material";
import { Inmate } from "@src/api/graphql";
import MeetingsTableMeeting from "@src/lib/Profiles/MeetingsTableMeeting";
import { FilterLabels, FilterOption } from "@src/lib/useAvailableFilters";
import { useTranslation } from "react-i18next";
import ScreenSection from "../ScreenSection";
import MeetingsTable from "./MeetingsTable";
import MessagesReadOnlyPanel from "./MessagesReadOnlyPanel";

type Props = {
  meetings: MeetingsTableMeeting[];
  loading: boolean;
  filter: FilterOption;
  availableFilters: FilterOption[];
  onFilterChange: (val: FilterOption) => void;
  // when given inmate data, show messages at appropriate facilities
  inmate?: Pick<Inmate, "id" | "fullName" | "inmateIdentification">;
};

export default function ActivitySection({
  meetings,
  loading,
  filter,
  availableFilters,
  onFilterChange,
  inmate,
}: Props) {
  const { t } = useTranslation();
  return (
    <ScreenSection
      title={t("Activity")}
      titlePosition="inner"
      extra={
        availableFilters.length ? (
          <div>
            <SelectInputBase
              value={filter}
              aria-label={t("Filter by activity")}
              onChange={(event) => {
                onFilterChange(event.target.value as FilterOption);
              }}
              size="small"
              items={availableFilters.map((o) => ({
                value: o,
                name: FilterLabels[o],
              }))}
            />
          </div>
        ) : undefined
      }
    >
      {filter === "messages" && inmate ? (
        <MessagesReadOnlyPanel inmate={inmate} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <MeetingsTable
            meetings={meetings}
            loading={loading}
            viewingProfileOf={inmate ? "inmate" : "visitor"}
          />
        </Box>
      )}
    </ScreenSection>
  );
}
