import { Check, Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { PrivacyLevel } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";
import { useTranslation } from "react-i18next";

export const labelPrivacyLevel = (
  privacyLevel: PrivacyLevel,
  opt?: { titleCase?: boolean },
): string => {
  const TITLES: Record<PrivacyLevel, string> = {
    [PrivacyLevel.Hidden]: i18n.t("Hidden"),
    [PrivacyLevel.Monitored]: i18n.t("Monitored"),
    [PrivacyLevel.UnmonitoredLogged]: i18n.t("Confidential"),
  };
  const LABELS: Record<PrivacyLevel, string> = {
    [PrivacyLevel.Hidden]: i18n.t("hidden"),
    [PrivacyLevel.Monitored]: i18n.t("monitored"),
    [PrivacyLevel.UnmonitoredLogged]: i18n.t("confidential"),
  };
  const label = LABELS[privacyLevel];
  return opt?.titleCase ? TITLES[privacyLevel] : label;
};

export const MonitoredPrivacyLabel = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 1 }}>
      <Typography variant="subtitle1">{t("Monitored")}</Typography>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("Live monitoring of voice calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("Recordings of calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("See who a resident talked with")}
        </Typography>
      </Box>
    </Box>
  );
};

export const UnmonitoredPrivacyLabel = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 1 }}>
      <Typography variant="subtitle1">{t("Confidential")}</Typography>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Live monitoring of voice calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Recordings of calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("See who a resident talked with")}
        </Typography>
      </Box>
    </Box>
  );
};

export const HiddenPrivacyLabel = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 1 }}>
      <Typography variant="subtitle1">{t("Hidden")}</Typography>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Live monitoring of voice calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Recordings of calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("See who a resident talked with")}
        </Typography>
      </Box>
    </Box>
  );
};
