import { Button, Dialog, useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { MeetingType, PrivacyLevel } from "@src/api/graphql";
import { GetFacilitySchedulesQuery } from "@src/graphql/GetFacilitySchedules.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useTranslation } from "react-i18next";
import { DeleteScheduleDocument } from "./DeleteSchedule.generated";

type Schedule = GetFacilitySchedulesQuery["facility"]["schedules"][number];

type Props = {
  schedule: Schedule;
  onClose: () => void;
};

export default function DeleteScheduleModal({ schedule, onClose }: Props) {
  const handleApolloError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();
  const [deleteSchedule] = useMutation(DeleteScheduleDocument, {
    onError: handleApolloError,
    refetchQueries: ["GetFacilitySchedules"],
    variables: {
      input: {
        scheduleId: schedule.id,
      },
    },
    onCompleted: () => {
      snackbarContext.alert("success", t("Schedule deleted successfully"));
      onClose();
    },
  });

  const { t } = useTranslation();

  return (
    <Dialog
      title={t("Delete {{scheduleName}}", { scheduleName: schedule.name })}
      fullWidth
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={1}>
          <Typography>
            {t(
              "Deleting this schedule will not cancel meetings that have already been scheduled. Once this schedule is deleted it cannot be restored.",
            )}
          </Typography>
          <Typography>
            {t("The following meeting types will be impacted by this change:")}
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item",
              },
            }}
          >
            {schedule.meetingTypes.includes(MeetingType.InPersonVisit) && (
              <ListItem>
                <Typography>
                  {schedule.privacyLevels.includes(PrivacyLevel.Monitored)
                    ? t("Monitored in-person visits")
                    : t("Confidential in-person visits")}
                </Typography>
              </ListItem>
            )}
            {schedule.meetingTypes.includes(MeetingType.VideoCall) && (
              <ListItem>
                <Typography>
                  {schedule.privacyLevels.includes(PrivacyLevel.Monitored)
                    ? t("Monitored video calls")
                    : t("Confidential video calls")}
                </Typography>
              </ListItem>
            )}
            {schedule.meetingTypes.includes(MeetingType.VoiceCall) && (
              <ListItem>
                <Typography>
                  {schedule.privacyLevels.includes(PrivacyLevel.Monitored)
                    ? t("Monitored voice calls")
                    : t("Confidential voice calls")}
                </Typography>
              </ListItem>
            )}
            {schedule.meetingTypes.includes(MeetingType.Webinar) && (
              <ListItem>
                {schedule.privacyLevels.includes(PrivacyLevel.Monitored)
                  ? t("Monitored webinars")
                  : t("Confidential webinars")}
              </ListItem>
            )}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => deleteSchedule()}
          color="error"
        >
          {t("Yes, delete schedule")}
        </Button>
        <Button variant="contained" autoFocus onClick={onClose}>
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
