import { Button } from "@ameelio/ui";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { Box, Theme, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import Timer from "@src/lib/LiveCall/Timer";
import VideoControlsMenu from "@src/lib/LiveCall/VideoControlsMenu";
import { MENU_BACKGROUND_COLOR } from "@src/theme";
import React from "react";
import { useTranslation } from "react-i18next";
import useBoolean from "../useBoolean";
import SendAlertDialog from "./SendAlertDialog";

interface Props {
  onToggleMute?: () => void;
  isMuted?: boolean;
  onToggleChat?: () => void;
  chatCollapsed?: boolean;
  hideVolume?: boolean;
  pinCall?: () => void;
  showSendAlert?: boolean;
  onAlert?: (alert: string) => void;
  onTerminate?: () => Promise<void>;
  scheduledEnd: number;
  kioskName?: string;
  children: React.ReactNode;
  sx?: SystemStyleObject<Theme>;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Debug: any;
  }
}

export default function VideoCall({
  pinCall,
  showSendAlert,
  onToggleMute,
  isMuted,
  onToggleChat,
  chatCollapsed,
  onAlert,
  onTerminate,
  scheduledEnd,
  kioskName,
  children,
  sx,
  hideVolume,
}: Props) {
  const { t } = useTranslation();
  const sendAlertDialog = useBoolean();
  return (
    <Box
      sx={{
        ...(sx || {}),
        position: "relative",
        backgroundColor: MENU_BACKGROUND_COLOR,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: 1,
          display: "flex",
          flexDirection: "row",
          height: 54,
          alignItems: "center",
          pr: 2,
          gap: 2,
        }}
      >
        <VideoControlsMenu
          onTerminate={onTerminate}
          pinCall={pinCall}
          onToggleMute={onToggleMute}
          isMuted={isMuted}
          onToggleChat={onToggleChat}
          chatCollapsed={chatCollapsed}
        />
        {kioskName ? (
          <Typography variant="body1" color="white" noWrap sx={{ flex: 1 }}>
            {t("Device: {{name}}", { name: kioskName })}
          </Typography>
        ) : (
          <Box sx={{ flexGrow: 1 }} />
        )}
        {showSendAlert && (
          <Button
            variant="contained"
            onClick={sendAlertDialog.enable}
            sx={{
              background: "#D32F2F",
              "&:hover": { background: "#DE3535" },
            }}
          >
            {t("Send alert")}
          </Button>
        )}
        <Timer endTime={scheduledEnd} />
      </Box>
      {children}
      {!hideVolume && !isMuted && (
        <VolumeUp
          onClick={onToggleMute}
          style={{
            position: "absolute",
            bottom: 8,
            right: 8,
            fontSize: 36,
            color: "green",
          }}
        />
      )}
      {!hideVolume && isMuted && (
        <VolumeOff
          onClick={onToggleMute}
          style={{
            position: "absolute",
            bottom: 8,
            right: 8,
            fontSize: 36,
            color: "red",
          }}
        />
      )}
      {sendAlertDialog.current && (
        <SendAlertDialog
          type="video"
          onClose={sendAlertDialog.disable}
          onSend={(message: string) => {
            if (!onAlert) return;
            onAlert(message);
          }}
        />
      )}
    </Box>
  );
}
