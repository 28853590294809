import {
  Button,
  Chip,
  ChipCustomColors,
  SelectInputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextInputBase,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, SelectChangeEvent } from "@mui/material";
import { FacilityFeature, OrganizationType } from "@src/api/graphql";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import NotEnabled from "@src/lib/NotEnabled";
import OrganizationLogo from "@src/lib/OrganizationLogo";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useFacilityFeature from "@src/lib/useFacilityFeature";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetConnectedOrganizationsDocument } from "./GetConnectedOrganizations.generated";

const OrganizationTypeColor: Record<OrganizationType, ChipCustomColors> = {
  [OrganizationType.Educational]: "green",
  [OrganizationType.Healthcare]: "grey",
  [OrganizationType.Legal]: "blue",
  [OrganizationType.Other]: "red",
};

const { history } = window;

export default function OrganizationsPage(): ReactElement {
  const { facility } = useGuaranteedFacilityContext();
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  // search filters are restored from page state for back navigation
  const [query, setQuery] = useState(
    isObjectWithKey(history.state, "query") &&
      typeof history.state.query === "string"
      ? history.state.query
      : "",
  );
  const [filter, setFilter] = useState(
    isObjectWithKey(history.state, "filter") &&
      typeof history.state.filter === "string"
      ? history.state.filter
      : "name",
  );

  useEffect(() => {
    history.replaceState({ filter, query }, "");
  }, [filter, query]);
  const handleApolloError = useApolloErrorHandler();
  const { loading, data } = useQuery(GetConnectedOrganizationsDocument, {
    variables: {
      facilityId: facility.id,
    },
    onError: handleApolloError,
  });

  const connectedOrgs = useMemo(
    () =>
      data?.facility.connectedOrganizations.filter((co) => {
        return query && query !== "all"
          ? co.name.toLowerCase().includes(query.toLowerCase()) ||
              co.type.toLowerCase() === query.toLowerCase()
          : true;
      }) || [],
    [data, query],
  );

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: connectedOrgs,
    resetOnChange: [query],
  });

  const hasWebinars = useFacilityFeature(FacilityFeature.Webinar);
  const hasConfidentialInPersonVisits = useFacilityFeature(
    FacilityFeature.ConfidentialInPersonVisit,
  );
  const hasConfidentialVideoCalls = useFacilityFeature(
    FacilityFeature.ConfidentialVideoCall,
  );
  const supportsProfessionals =
    hasWebinars || hasConfidentialInPersonVisits || hasConfidentialVideoCalls;

  if (!supportsProfessionals) return <NotEnabled />;

  return (
    <Box>
      <Header
        title={t("Professional Partners")}
        subtitle={t(
          "View professional partner organizations and access their information.",
        )}
      >
        <Box
          display="flex"
          gap={2}
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
        >
          <Box key="filter-1" display="flex" alignItems="flex-end">
            <SelectInputBase
              key="filter-type"
              disabled={loading}
              size="small"
              aria-label={t("Filter type")}
              value={filter}
              style={{ width: 120 }}
              onChange={(event: SelectChangeEvent<unknown>) => {
                setQuery("");
                setFilter(event.target.value as string);
              }}
              items={[
                { value: "name", name: t("Name") },
                { value: "type", name: t("Type") },
              ]}
            />
          </Box>
          <Box key="filter-2" display="flex" alignItems="flex-end">
            {filter === "name" ? (
              <TextInputBase
                size="small"
                label={t("Organization name")}
                aria-label={t("Search by org name...")}
                disabled={loading}
                sx={{
                  width: "auto",
                  minWidth: { sm: 250 },
                }}
                placeholder={t("Search by org name...")}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
            ) : filter === "type" ? (
              <SelectInputBase
                key="filter-org-type"
                disabled={loading}
                size="small"
                label={t("Organization type")}
                aria-label={t("Search by org type")}
                placeholder={t("Search by org type")}
                value={query}
                style={{ width: "auto", minWidth: 250 }}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  setQuery((event.target.value as string) || "");
                }}
                items={[
                  {
                    name: t("All"),
                    value: "all",
                  },
                  ...Object.keys(OrganizationType).map((key) => ({
                    value: key,
                    name: key,
                  })),
                ]}
              />
            ) : null}
          </Box>
        </Box>
      </Header>
      <Box p={3}>
        {loading && <PageLoader />}
        {!loading && (
          <>
            <TableContainer>
              <Table aria-label={t("Organizations table")}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell>{t("Type")}</TableCell>
                    <TableCell>{t("Size")}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.map(
                    ({ id, name, type, memberships, logoImageUrl }) => (
                      <TableRow key={id}>
                        <TableCell>
                          <OrganizationLogo
                            organization={{ name, logoImageUrl }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {name}
                        </TableCell>
                        <TableCell>
                          <Chip
                            color={OrganizationTypeColor[type]}
                            label={type}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip color="blue" label={memberships.length} />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => navigate(`/organizations/${id}`)}
                          >
                            {t("See details")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              totalCount={connectedOrgs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
