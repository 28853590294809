import { Link } from "@mui/material";
import { MeetingType, TimelineEventVerb } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";
import entitlementLabel from "@src/lib/entitlementLabel";
import facilityServiceLabel from "@src/lib/facilityServiceLabel";
import { labelMeetingType } from "@src/lib/meeting";
import { TypographyText } from "@src/lib/typography";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { Trans, useTranslation } from "react-i18next";
import { GetStaffTimelineQuery } from "./GetStaffTimeline.generated";

type StaffTimelineEvent =
  GetStaffTimelineQuery["staff"]["timelineEvents"]["edges"][0]["node"];

type Props = {
  event: StaffTimelineEvent;
};

function formatSettingProperty(prop: string) {
  const settingPropertyMap: Record<string, string> = {
    name: i18n.t("Facility name"),
    email: i18n.t("Email"),
    phone: i18n.t("Phone number"),
    policyLink: i18n.t("Policy URL"),
    visitationFormLink: i18n.t("Visitation form URL"),
    defaultVisitQuota: i18n.t("Default personal in-person visit quota"),
    defaultCallQuota: i18n.t("Default personal video call quota"),
    defaultVoiceCallTimeQuota: i18n.t("Default voice call time quota"),
    maxVoiceCallDuration: i18n.t("Maximum voice call duration"),
    schedulingResetDay: i18n.t("Personal video scheduling reset day"),
    voiceCallResetDay: i18n.t("Voice call quota reset day"),
    ipvResetDay: i18n.t("Personal in-person visit scheduling reset day"),
    defaultEmessageDelay: i18n.t("Default e-message delay"),
    defaultNeedsApproval: i18n.t("Default video meeting approval requirement"),
    defaultIpvNeedsAppproval: i18n.t(
      "Default in-person visit approval requirement",
    ),
    defaultNeedsMonitor: i18n.t("Default monitoring requirement"),
    schedulingCutoff: i18n.t("Personal video call scheduling cutoff"),
    providerSchedulingCutoff: i18n.t("Professional video scheduling cutoff"),
    ipvSchedulingCutoff: i18n.t("Personal in-person visit scheduling cutoff"),
    scheduleWeeksAvailable: i18n.t("Personal video scheduling weeks available"),
    providerWeeksAvailable: i18n.t(
      "Professional video scheduling weeks available",
    ),
    ipvWeeksAvailable: i18n.t(
      "Personal in-person visit scheduling weeks available",
    ),
  };
  if (prop in settingPropertyMap) return settingPropertyMap[prop];
  return prop;
}

function formatFacilityApprovedPhoneProperty(prop: string) {
  const facilityApprovedPhonePropertyMap: Record<string, string> = {
    name: i18n.t("name"),
    description: i18n.t("description"),
    privacyLevel: i18n.t("privacy level"),
  };
  if (prop in facilityApprovedPhonePropertyMap)
    return facilityApprovedPhonePropertyMap[prop];
  return prop;
}

const labelTimelineEventVerb = (verb: TimelineEventVerb): string => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled");
    case TimelineEventVerb.Create:
      return i18n.t("Created");
    case TimelineEventVerb.Read:
      return i18n.t("Read");
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected");
    case TimelineEventVerb.Update:
      return i18n.t("Updated");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled");
    case TimelineEventVerb.Monitor:
      return i18n.t("Monitored");
    default:
      return verb;
  }
};

const getCallDescription = (verb: TimelineEventVerb) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved call");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled call");
    case TimelineEventVerb.Create:
      return i18n.t("Scheduled call");
    case TimelineEventVerb.Read:
      return i18n.t("Read call");
    case TimelineEventVerb.Reject:
      return i18n.t("Declined call");
    case TimelineEventVerb.Update:
      return i18n.t("Updated call");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled call");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled call");
    default:
      return i18n.t("{{verb}} call", {
        verb: labelTimelineEventVerb(verb),
      });
  }
};

const getConnectionRequestDescription = (verb: TimelineEventVerb) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved request");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled request");
    case TimelineEventVerb.Create:
      return i18n.t("Created request");
    case TimelineEventVerb.Read:
      return i18n.t("Read request");
    case TimelineEventVerb.Reject:
      return i18n.t("Declined request");
    case TimelineEventVerb.Update:
      return i18n.t("Updated request");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled request");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled request");
    default:
      return i18n.t("{{verb}} request", { verb: labelTimelineEventVerb(verb) });
  }
};

const getConnectionRestrictionDescription = (
  verb: TimelineEventVerb,
  service: string,
) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved restriction on {{service}} between two parties", {
        service,
      });
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled restriction on {{service}} between two parties", {
        service,
      });
    case TimelineEventVerb.Create:
      return i18n.t("Created restriction on {{service}} between two parties", {
        service,
      });
    case TimelineEventVerb.Read:
      return i18n.t("Read restriction on {{service}} between two parties", {
        service,
      });
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected restriction on {{service}} between two parties", {
        service,
      });
    case TimelineEventVerb.Update:
      return i18n.t("Updated restriction on {{service}} between two parties", {
        service,
      });
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled restriction on {{service}} between two parties", {
        service,
      });
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled restriction on {{service}} between two parties", {
        service,
      });
    default:
      return i18n.t("{{verb}} restriction on {{service}} between two parties", {
        verb: labelTimelineEventVerb(verb),
        service,
      });
  }
};

const getFacilityApprovedPhoneDescription = (
  verb: TimelineEventVerb,
  number: string,
  metadata: Record<string, string> | null,
) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved {{number}}", {
        number,
      });
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled {{number}}", {
        number,
      });
    case TimelineEventVerb.Create:
      return i18n.t("Created {{number}}", { number });
    case TimelineEventVerb.Read:
      return i18n.t("Read {{number}}", {
        number,
      });
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected {{number}}", {
        number,
      });
    case TimelineEventVerb.Update:
      return metadata
        ? i18n.t("Updated {{number}} properties: {{properties}}", {
            number,
            properties: Object.keys(metadata)
              .map(formatFacilityApprovedPhoneProperty)
              .join(", "),
          })
        : i18n.t("Updated {{number}}", {
            number,
          });
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled {{number}}", {
        number,
      });
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled {{number}}", {
        number,
      });
    default:
      return i18n.t("{{verb}} {{number}}", {
        verb: labelTimelineEventVerb(verb),
        number,
      });
  }
};

const getKeywordDescription = (verb: TimelineEventVerb, keyword: string) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved keyword '{{keyword}}'", { keyword });
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled keyword '{{keyword}}'", { keyword });
    case TimelineEventVerb.Create:
      return i18n.t("Created keyword '{{keyword}}'", {
        keyword,
      });
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected keyword '{{keyword}}'", { keyword });
    case TimelineEventVerb.Read:
      return i18n.t("Read keyword '{{keyword}}'", { keyword });
    case TimelineEventVerb.Update:
      return i18n.t("Updated keyword '{{keyword}}'", { keyword });
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled keyword '{{keyword}}'", { keyword });
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled keyword '{{keyword}}'", { keyword });
    default:
      return i18n.t("{{verb}} keyword '{{keyword}}'", {
        verb: labelTimelineEventVerb(verb),
        keyword,
      });
  }
};

const getKioskDescription = (verb: TimelineEventVerb) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved resource");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled resource");
    case TimelineEventVerb.Create:
      return i18n.t("Created resource");
    case TimelineEventVerb.Read:
      return i18n.t("Read resource");
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected resource");
    case TimelineEventVerb.Update:
      return i18n.t("Updated resource");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled resource");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled resource");
    default:
      return i18n.t("{{verb}} resource", {
        verb: labelTimelineEventVerb(verb),
      });
  }
};

const getMeetingDescription = (
  verb: TimelineEventVerb,
  meetingType: MeetingType,
) => {
  const type = labelMeetingType(meetingType);
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved {{meetingType}}", { meetingType: type });
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled {{meetingType}}", { meetingType: type });
    case TimelineEventVerb.Create:
      return i18n.t("Scheduled {{meetingType}}", { meetingType: type });
    case TimelineEventVerb.Read:
      return i18n.t("Read {{meetingType}}", { meetingType: type });
    case TimelineEventVerb.Reject:
      return i18n.t("Declined {{meetingType}}", { meetingType: type });
    case TimelineEventVerb.Update:
      return i18n.t("Updated {{meetingType}}", { meetingType: type });
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled {{meetingType}}", { meetingType: type });
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled {{meetingType}}", { meetingType: type });
    default:
      return i18n.t("{{verb}} {{meetingType}}", {
        verb: labelTimelineEventVerb(verb),
        meetingType: type,
      });
  }
};

const getMessageDescription = (verb: TimelineEventVerb) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved message");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled message");
    case TimelineEventVerb.Create:
      return i18n.t("Created message");
    case TimelineEventVerb.Read:
      return i18n.t("Read message");
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected message");
    case TimelineEventVerb.Update:
      return i18n.t("Updated message");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled message");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled message");
    default:
      return i18n.t("{{verb}} message", {
        verb: labelTimelineEventVerb(verb),
      });
  }
};

const getStaffEntitlementDescription = (
  verb: TimelineEventVerb,
  entitlement: string,
) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved staff entitlement {{entitlement}}", {
        entitlement,
      });
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled staff entitlement {{entitlement}}", {
        entitlement,
      });
    case TimelineEventVerb.Create:
      return i18n.t("Created staff entitlement {{entitlement}}", {
        entitlement,
      });
    case TimelineEventVerb.Read:
      return i18n.t("Read staff entitlement {{entitlement}}", {
        entitlement,
      });
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected staff entitlement {{entitlement}}", {
        entitlement,
      });
    case TimelineEventVerb.Update:
      return i18n.t("Updated staff entitlement {{entitlement}}", {
        entitlement,
      });
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled staff entitlement {{entitlement}}", {
        entitlement,
      });
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled staff entitlement {{entitlement}}", {
        entitlement,
      });
    default:
      return i18n.t("{{verb}} staff entitlement {{entitlement}}", {
        verb: labelTimelineEventVerb(verb),
        entitlement,
      });
  }
};

const getStaffPositionDescription = (
  verb: TimelineEventVerb,
  facilityName: string,
) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved staff position at {{facilityName}}", {
        facilityName,
      });
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled staff position at {{facilityName}}", {
        facilityName,
      });
    case TimelineEventVerb.Create:
      return i18n.t("Created staff position at {{facilityName}}", {
        facilityName,
      });
    case TimelineEventVerb.Read:
      return i18n.t("Read staff position at {{facilityName}}", {
        facilityName,
      });
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected staff position at {{facilityName}}", {
        facilityName,
      });
    case TimelineEventVerb.Update:
      return i18n.t("Updated staff position at {{facilityName}}", {
        facilityName,
      });
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled staff position at {{facilityName}}", {
        facilityName,
      });
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled staff position at {{facilityName}}", {
        facilityName,
      });
    default:
      return i18n.t("{{verb}} staff position at {{facilityName}}", {
        verb: labelTimelineEventVerb(verb),
        facilityName,
      });
  }
};

const getSystemUserDescription = (verb: TimelineEventVerb) => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved ID");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled ID");
    case TimelineEventVerb.Create:
      return i18n.t("Created ID");
    case TimelineEventVerb.Read:
      return i18n.t("Read ID");
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected ID");
    case TimelineEventVerb.Update:
      return i18n.t("Updated ID");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled ID");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled ID");
    default:
      return i18n.t("{{verb}} ID", { verb: labelTimelineEventVerb(verb) });
  }
};

export default function TimelineEventObjectDescription({ event }: Props) {
  const { t } = useTranslation();
  const navigate = useAppNavigate();
  const object = event.object;
  const metadata = event.metadata
    ? (JSON.parse(event.metadata) as Record<string, string>)
    : null;

  switch (object.__typename) {
    case "Message":
      return (
        <TypographyText>{getMessageDescription(event.verb)}</TypographyText>
      );
    case "MessageKeyword":
      return (
        <TypographyText>
          {getKeywordDescription(event.verb, object.name)}
        </TypographyText>
      );
    case "Connection":
      return (
        <TypographyText>
          {getConnectionRequestDescription(event.verb)}
        </TypographyText>
      );
    case "ConnectionRestriction":
      return (
        <TypographyText>
          {getConnectionRestrictionDescription(
            event.verb,
            facilityServiceLabel(object.service),
          )}
        </TypographyText>
      );
    case "StaffPosition":
      return (
        <TypographyText>
          {getStaffPositionDescription(event.verb, object.facility.publicId)}
        </TypographyText>
      );
    case "StaffEntitlement":
      return (
        <TypographyText>
          {getStaffEntitlementDescription(
            event.verb,
            entitlementLabel(object.entitlement),
          )}
        </TypographyText>
      );
    case "Kiosk":
      return <TypographyText>{getKioskDescription(event.verb)}</TypographyText>;
    case "FacilityApprovedPhone":
      return (
        <TypographyText>
          {getFacilityApprovedPhoneDescription(
            event.verb,
            object.number,
            metadata,
          )}
        </TypographyText>
      );
    case "Call":
    case "Meeting":
      if (metadata?.detail === "accessed_recording") {
        return (
          <TypographyText>
            <Trans
              t={i18n.t}
              defaults="Accessed <meetingDetailsLink>call</meetingDetailsLink> recording"
              components={{
                meetingDetailsLink: (
                  <Link
                    onClick={() =>
                      navigate(
                        `/meetings/${object.__typename === "Meeting" ? object.id : object.meeting.id}`,
                      )
                    }
                  />
                ),
              }}
            />
          </TypographyText>
        );
      } else if (object.__typename === "Meeting") {
        return (
          <TypographyText>
            {getMeetingDescription(event.verb, object.meetingType)}
          </TypographyText>
        );
      } else {
        return (
          <TypographyText>{getCallDescription(event.verb)}</TypographyText>
        );
      }
    case "Facility":
      if (metadata?.changedFrom) {
        return (
          <TypographyText>
            {t("Changed properties:")}{" "}
            {Object.keys(metadata.changedFrom)
              .map(formatSettingProperty)
              .join(", ")}
          </TypographyText>
        );
      } else if (metadata?.detail === "uploaded_roster") {
        return <TypographyText>{t("Uploaded facility roster")}</TypographyText>;
      } else {
        return null;
      }
    case "SystemUser":
      return (
        <TypographyText>{getSystemUserDescription(event.verb)}</TypographyText>
      );
    default:
      return null;
  }
}
