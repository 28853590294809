import { groupBy } from "@ameelio/core";
import { Button, useSnackbarContext } from "@ameelio/ui";
import { onlyMobile } from "@ameelio/ui/src/helpers/responsiveHelpers";
import { DownloadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Box, Stack, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Screenshot as GraphQLScreenshot } from "@src/api/graphql";
import { DownloadScreenshotsDocument } from "@src/graphql/DownloadScreenshots.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Result from "@src/lib/Result";
import ScreenSection from "@src/lib/ScreenSection";
import { formatDate } from "@src/lib/Time";
import { MENU_BACKGROUND_COLOR, MENU_SECONDARY_COLOR } from "@src/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Screenshot = Omit<GraphQLScreenshot, "user"> & {
  user: { fullName: string };
};

function ScreenshotBox({ screenshot }: { screenshot: Screenshot }) {
  const isMobile = onlyMobile(useMediaQuery);

  return screenshot.url ? (
    <Box sx={{ textAlign: "center", maxWidth: isMobile ? "100%" : "300px" }}>
      <img
        src={screenshot.url}
        style={{ maxWidth: isMobile ? "100%" : "300px", width: "100%" }}
        key={screenshot.url}
        alt={screenshot.user.fullName}
      />
      <Typography
        variant="body2"
        color="white"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {screenshot.user.fullName}
      </Typography>
    </Box>
  ) : null;
}

const DownloadScreenshotsButton = ({ meetingId }: { meetingId: string }) => {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const [downloading, setDownloading] = useState(false);
  const handleApolloError = useApolloErrorHandler();
  const [downloadScreenshots] = useMutation(DownloadScreenshotsDocument, {
    onError: handleApolloError,
    onCompleted: (data) => {
      const { url } = data.downloadScreenshots;
      if (url) {
        const downloadLink = document.createElement("a");
        downloadLink.target = "_blank";
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        snackbarContext.alert("error", t("Fetch of PDF url failed."));
      }
      setDownloading(false);
    },
  });

  return (
    <Button
      onClick={() => {
        setDownloading(true);
        void downloadScreenshots({
          variables: {
            input: {
              meetingId: meetingId,
            },
          },
        });
      }}
      key="2"
      variant="contained"
      disabled={downloading}
    >
      <DownloadOutlined style={{ verticalAlign: "middle" }} />
      <span style={{ verticalAlign: "middle", marginLeft: 8 }}>
        {t("Download PDF")}
      </span>
    </Button>
  );
};

export default function ScreenshotsModule({
  meetingId,
  screenshots,
}: {
  meetingId: string;
  screenshots: Screenshot[];
}) {
  const { t } = useTranslation();
  const screenshotsGroups = groupBy(screenshots, (sc) =>
    new Date(sc.createdAt).toISOString(),
  );

  if (!screenshots.length) {
    return <Result variant="404" title={t("No screenshots available")} />;
  }

  return (
    <ScreenSection
      title={t("Screenshots")}
      titlePosition="inner"
      extra={
        screenshots.length ? (
          <DownloadScreenshotsButton meetingId={meetingId} />
        ) : null
      }
    >
      <Box
        sx={{
          backgroundColor: MENU_SECONDARY_COLOR,
          position: "relative",
          p: 2,
          minHeight: { xs: "400px" },
          maxHeight: { xs: "800px" },
          overflowY: "auto",
        }}
      >
        <Stack>
          {Object.entries(screenshotsGroups).map(([ts, group]) => (
            <Stack
              spacing={2}
              alignItems="center"
              p={2}
              style={{ backgroundColor: MENU_BACKGROUND_COLOR }}
              key={ts}
            >
              <>
                <Typography variant="body1" color="white">
                  {formatDate(new Date(ts), "datetime")}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                  flexWrap="wrap"
                >
                  {group?.map((sc, i) => (
                    <ScreenshotBox key={i} screenshot={sc} />
                  ))}
                </Stack>
              </>
            </Stack>
          ))}
        </Stack>
      </Box>
    </ScreenSection>
  );
}
