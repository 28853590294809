import { belowLargeTablet, Button, Chip } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  Box,
  Link,
  useMediaQuery as measureScreenWidth,
  Typography,
} from "@mui/material";
import {
  FacilityFeature,
  OrganizationMembershipStatus,
} from "@src/api/graphql";
import AgeGroupTag from "@src/lib/AgeGroupTag";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import { isMinorWithoutID } from "@src/lib/Common";
import Descriptions from "@src/lib/Descriptions";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import PageLoader from "@src/lib/PageLoader";
import ActivitySection from "@src/lib/Profiles/ActivitySection";
import ContactsSection from "@src/lib/Profiles/ContactsList";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import stripInvalid from "@src/lib/stripInvalid";
import SystemRelationshipStatusTag from "@src/lib/SystemRelationshipStatusTag";
import { formatDate, getDisplayBirthday } from "@src/lib/Time";
import useAvailableFilters, {
  FilterOption,
  filterOptions,
  FilterTypes,
} from "@src/lib/useAvailableFilters";
import useBoolean from "@src/lib/useBoolean.ts";
import { useReverseFetchAll } from "@src/lib/useFetchAll";
import UpdatePhoneNumberDialog from "@src/pages/VisitorPage/UpdatePhoneNumberDialog.tsx";
import { WRAPPER_STYLE } from "@src/styles/styles";
import Joi from "joi";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { GetVisitorDocument } from "./GetVisitor.generated";
import { GetVisitorMeetingsDocument } from "./GetVisitorMeetings.generated";
import VisitorIDButton from "./VisitorIDButton";

type VisitorActivityCardProps = {
  visitorId: string;
  facilityId: string;
};

const { history } = window;

// load state from history (back nav)
function parseHistoryState() {
  return stripInvalid(
    Joi.object<{ meetingType?: FilterOption }>({
      meetingType: Joi.string().valid(...filterOptions),
    }),
    history.state,
  );
}

const VisitorActivityCard = ({
  visitorId,
  facilityId,
}: VisitorActivityCardProps) => {
  const availableFilters = useAvailableFilters();

  const [filter, setFilter] = useState<Record<string, FilterOption>>(() => {
    const historyState = parseHistoryState();
    return {
      meetingType: historyState.meetingType || "all",
    };
  });

  useEffect(() => {
    history.replaceState(filter, "");
  }, [filter]);

  const meetingTypes = FilterTypes[filter.meetingType];
  const handleApolloError = useApolloErrorHandler();
  const { loading, data, error, fetchMore } = useQuery(
    GetVisitorMeetingsDocument,
    {
      variables: {
        visitorId,
        facilityId,
        // The query is skipped if meetingType is undefined, but
        // $meetingType in the query definition is required
        // because it needs to be passed into the array argument for meetingType
        // in the `meetings` field resolver, and that has it as required when included in the array
        meetingTypes: meetingTypes!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        cursor: undefined,
      },
      onError: handleApolloError,
      skip: !meetingTypes,
      fetchPolicy: "cache-and-network",
    },
  );

  useReverseFetchAll({
    pageInfo: data?.visitor.meetings.pageInfo,
    fetchMore,
  });

  if (error) throw error;

  const meetings = useMemo(
    () => data?.visitor.meetings.edges.map(({ node }) => node).reverse() || [],
    [data],
  );

  return (
    <ActivitySection
      meetings={meetings}
      loading={loading}
      filter={filter.meetingType}
      availableFilters={availableFilters}
      onFilterChange={(val) => {
        setFilter({ meetingType: val });
      }}
    />
  );
};

export default function VisitorPage() {
  const { id: visitorId } = useParams<{ id: string }>();
  if (!visitorId) throw new Error("missing visitorId param");
  const { t } = useTranslation();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  const {
    facility: {
      id: facilityId,
      system: { id: systemId },
      features: facilityFeatures,
    },
  } = useGuaranteedFacilityContext();
  const handleApolloError = useApolloErrorHandler();
  const { data, error } = useQuery(GetVisitorDocument, {
    variables: { visitorId, facilityId, systemId },
    onError: handleApolloError,
    fetchPolicy: "cache-and-network",
  });

  const updatePhoneContactDialog = useBoolean();

  const facilitySupportsPstnCalls = facilityFeatures.includes(
    FacilityFeature.PstnCall,
  );

  if (error) throw error;
  if (!data?.visitor) return <PageLoader />;

  const { visitor } = data;
  const { connections, organizationMemberships } = visitor;
  const organization =
    organizationMemberships.find(
      (o) => o.status === OrganizationMembershipStatus.Active,
    )?.organization || undefined;

  return (
    <Box>
      <Header
        title={visitor.fullName}
        extra={[
          facilitySupportsPstnCalls && visitor.pstnNumber && (
            <div key="pstn-contact" className="flex gap-x-4">
              <Button
                variant="contained"
                onClick={updatePhoneContactDialog.enable}
              >
                {t("Update phone number")}
              </Button>
            </div>
          ),
          (visitor.identity || !visitor.pstnNumber) && (
            <div key="view-ids" className="flex gap-x-4">
              <VisitorIDButton visitor={visitor} />
            </div>
          ),
        ]}
        breadcrumb={
          <Breadcrumbs
            paths={[
              { path: "/visitors", label: t("Visitors") },
              { path: `/visitors/${visitor.id}`, label: visitor.fullName },
            ]}
          />
        }
        showBack
      >
        <Descriptions column={isMobileOrSmallTablet ? 1 : 3}>
          {visitor.systemRelationship &&
            (visitor.identity || !visitor.pstnNumber) && (
              <Descriptions.Item label={t("ID Status")}>
                {isMinorWithoutID(visitor) ? (
                  <AgeGroupTag dateOfBirth={visitor.dateOfBirth} />
                ) : (
                  <SystemRelationshipStatusTag
                    status={visitor.systemRelationship.status}
                  />
                )}
              </Descriptions.Item>
            )}
          {!visitor.pstnNumber && (
            <Descriptions.Item
              label={t("Email")}
              value={visitor.email || t("Unknown")}
            />
          )}
          {!visitor.pstnNumber && (
            <Descriptions.Item
              label={t("DOB")}
              value={
                visitor.dateOfBirth
                  ? getDisplayBirthday(visitor.dateOfBirth)
                  : t("Unknown")
              }
            />
          )}
          {
            <Descriptions.Item
              label={t("Phone")}
              value={visitor.pstnNumber || visitor.phone}
            />
          }
          {organization && (
            <Descriptions.Item label={t("Organization")}>
              <Link
                component={RouterLink}
                to={`/organizations/${organization.id}`}
              >
                {organization.name}
              </Link>
            </Descriptions.Item>
          )}
          {facilityFeatures.includes(FacilityFeature.VoiceCall) && (
            <Descriptions.Item label={t("Voice calls")}>
              {!visitor.unavailableUntil ||
              new Date(visitor.unavailableUntil) < new Date() ? (
                <Chip color="green" label={t("Available")} />
              ) : (
                <Typography variant="body2" color="error">
                  {t("Unavailable until {{date}}", {
                    date: formatDate(visitor.unavailableUntil, "datetime"),
                  })}
                </Typography>
              )}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Header>
      <div style={WRAPPER_STYLE}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: 4,
          }}
        >
          <Box sx={{ flex: { xs: "1 1 auto", lg: 3 } }}>
            <VisitorActivityCard
              visitorId={visitorId}
              facilityId={facilityId}
            />
          </Box>

          <Box sx={{ flex: { xs: "1 1 auto", lg: 1 } }}>
            <ContactsSection connections={connections} type={"visitor"} />
          </Box>
        </Box>
      </div>
      {updatePhoneContactDialog.current && (
        <UpdatePhoneNumberDialog
          visitor={visitor}
          onClose={updatePhoneContactDialog.disable}
        />
      )}
    </Box>
  );
}
