/**
 * uniqBy returns de-duplicated items from an array using a specific key
 * https://gist.github.com/lednhatkhanh/af41ad599568a776e09332b8cd716db6
 */
const uniqBy = <T>(arr: T[], key: keyof T): T[] => {
  return Object.values(
    arr.reduce(
      (map, item) => ({
        ...map,
        // NOTE: eliminating the possibility of a `[object Object]` key
        // is not currently worthwhile
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${item[key]}`]: item,
      }),
      {},
    ),
  );
};

export default uniqBy;
