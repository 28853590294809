import { useEffect } from "react";

/**
 * useMountEffect will run the effect when the hook is mounted.
 */
export default function useMountEffect(effect: () => void) {
  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
