// toCSV is adapted from react-csv, licensed under MIT
// https://github.com/react-csv/react-csv/blob/d1153c101b95c109b5bcf5a5c2a370a81d522f1e/src/core.js
import { uniq } from "@ameelio/core";
import { saveAs } from "file-saver";

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

type ArrayOfObjects = Record<string, unknown>[];
type ArrayOfArrays = unknown[][];
type Options = {
  delimiter?: string;
  separator?: string;
};

const isArrayOfObjects = (array: unknown): array is ArrayOfObjects =>
  Array.isArray(array) &&
  array.every((row) => typeof row === "object" && !(row instanceof Array));

const isArrayOfArrays = (array: unknown): array is ArrayOfArrays =>
  Array.isArray(array) && array.every((row) => Array.isArray(row));

const headersFromObjects = (array: ArrayOfObjects): string[] =>
  uniq(array.flatMap((obj) => Object.keys(obj)));

const flattenObjects = (objects: ArrayOfObjects): ArrayOfArrays => {
  const headers = headersFromObjects(objects);
  const data = objects.map((object) =>
    headers.map((header) => (header in object ? object[header] : "")),
  );
  return [headers, ...data];
};

const serialize = (val: unknown, delimiter = '"'): string => {
  if (typeof val === "undefined" || val === null) return "";
  if (typeof val === "number" || typeof val === "boolean")
    return val.toString();
  if (val instanceof Date) return val.toISOString();
  if (typeof val === "object" || typeof val === "string") {
    // The linter seems mistaken here because "val" is cast to a string
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return `${delimiter}${val
      .toString()
      .replace(
        new RegExp(delimiter, "g"),
        `${delimiter}${delimiter}`,
      )}${delimiter}`;
  }
  throw new TypeError(`Unhandled value type ${typeof val}`);
};

const stringify = (
  data: ArrayOfArrays,
  { delimiter, separator }: Options = {},
) => {
  return data
    .map((row) => row.map((val) => serialize(val, delimiter)).join(separator))
    .join(`\n`);
};

export const toCSV = (
  data: ArrayOfObjects | ArrayOfArrays,
  options: Options = {},
): string => {
  if (isArrayOfObjects(data)) return stringify(flattenObjects(data), options);
  if (isArrayOfArrays(data)) return stringify(data, options);
  throw new TypeError(
    `Data should be an array of arrays or an array of objects`,
  );
};

export const toCSVBlob = (
  data: Record<string, unknown>[] | unknown[][],
  options: Options = {},
) => {
  const csv = toCSV(data, options);
  const type = isSafari() ? "application/csv" : "text/csv";
  return new Blob([csv], { type });
};

export const downloadCSV = (
  data: unknown[][] | Record<string, unknown>[],
  filename: string,
) => {
  const blob = toCSVBlob(data);
  saveAs(blob, `${filename}.csv`);
};
