import { Button } from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTimeInput from "./CustomTimeInput";
import SelectAvailability, {
  getMaxVisitorsForCapacity,
} from "./SelectAvailability";
import { Availability, AvailableKiosk, Data } from "./types";

type Props = {
  inmateId: string;
  meetingId?: string;
  data: Data;
  onBack: () => void;
  onSubmit: ({
    availability,
    kiosk,
    customTimeChosen,
  }: {
    availability: Availability;
    kiosk: AvailableKiosk;
    customTimeChosen: boolean;
  }) => void;
  stepPosition: number;
  numSteps: number;
};

export default function SelectTimeStep({
  inmateId,
  meetingId,
  data,
  onBack,
  onSubmit,
  numSteps,
  stepPosition,
}: Props) {
  // type narrowing
  if (!data.meetingType) throw new Error("invalid meeting type");
  const { t } = useTranslation();

  // Meeting time selection mode
  const [selectingCustomTime, setSelectingCustomTime] = useState<boolean>(
    !!data.customTimeChosen, // means scheduler had entered a custom time and proceeded
  );

  // Selected kiosk
  const [kiosk, setKiosk] = useState<AvailableKiosk | undefined>(data.kiosk);

  // Selected date
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    data.availability ? new Date(data.availability.interval.startAt) : null,
  );

  // Pre-configured schedule availability
  const [availability, setAvailability] = useState<Availability | undefined>(
    data.availability &&
      data.kiosk &&
      (data.registeredGuests?.length || 0) +
        (data.unregisteredGuests?.length || 0) >
        getMaxVisitorsForCapacity(data.kiosk.capacity)
      ? undefined
      : data.availability,
  );

  // used to clear the selected availability when date changes
  useEffect(() => {
    // This guards against an initial case when selected date is
    // initialized with a default value
    if (data.availability) return;

    // Only set to undefined if the date doesn't exist or
    // if it has changed from a previously-selected date
    setAvailability(undefined);
  }, [data, setAvailability]);

  return (
    <Stack spacing={3}>
      <Typography variant="h2">
        {t("Step {{stepPosition}} of {{numSteps}}: Specify a meeting time", {
          stepPosition,
          numSteps,
        })}
      </Typography>
      <Typography variant="body1" color="text.primary">
        {t(
          "Please select the desired day to see a list of available meeting times. You may also add a custom time on any date. Times are limited by available locations or devices.",
        )}
      </Typography>
      <SelectAvailability
        inmateId={inmateId}
        meetingId={meetingId}
        data={data}
        date={selectedDate}
        selectingCustomTime={selectingCustomTime}
        selectedAvailability={availability}
        onUpdateDate={(value) => {
          setSelectedDate(value);
          setAvailability(undefined);
          setKiosk(undefined);
          // if the user had previously selected
          // a custom time and that component is
          // mounted, hide and reset state
          if (selectingCustomTime) setSelectingCustomTime(false);
        }}
        onUpdateSelectCustomTime={() => {
          setSelectingCustomTime(!selectingCustomTime);
          setAvailability(undefined);
          setKiosk(undefined);
        }}
        onUpdateMeetingInfo={({ availability, kiosk }) => {
          setAvailability(availability);
          setKiosk(kiosk);
          // The user could click on an availability
          // after previously thinking they wanted to
          // set a custom time, so this resets state
          if (selectingCustomTime) setSelectingCustomTime(false);
        }}
      />
      {selectingCustomTime && selectedDate && (
        <CustomTimeInput
          data={data}
          selectedDate={selectedDate}
          value={availability && kiosk ? { availability, kiosk } : undefined}
          onChange={(value) => {
            if (value) {
              setAvailability(value.availability);
              setKiosk(value.kiosk);
            } else {
              setAvailability(undefined);
              setKiosk(undefined);
            }
          }}
        />
      )}
      <Box mt={3} gap={1} display="flex" justifyContent="flex-end">
        <Button variant="outlined" onClick={onBack}>
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          disabled={!(availability && kiosk)}
          onClick={() => {
            if (!(availability && kiosk)) return;
            onSubmit({
              availability,
              kiosk,
              customTimeChosen: selectingCustomTime,
            });
          }}
        >
          {t("Next")}
        </Button>
      </Box>
    </Stack>
  );
}
