import { Media } from "@ameelio/ui";
import { Card, CardActionArea, Typography } from "@mui/material";
import Avatar from "@src/lib/Avatar";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { Path } from "@src/pages/routes";

type Props = {
  to: Path;
  name: string;
  avatarUrl?: string;
  fallbackIcon?: React.ReactElement;
  children: React.ReactNode;
};

export default function AvatarCard({
  to,
  name,
  avatarUrl,
  fallbackIcon,
  children,
}: Props) {
  const navigate = useAppNavigate();
  return (
    <Card variant="outlined" sx={{ width: 1 }}>
      <CardActionArea onClick={() => navigate(to)} sx={{ px: 2, py: 1.5 }}>
        <Media
          image={
            <Avatar
              src={avatarUrl}
              fallback={name}
              fallbackIcon={fallbackIcon || undefined}
            />
          }
          gap={1}
        >
          <Typography variant="body1">{name}</Typography>
          {children}
        </Media>
      </CardActionArea>
    </Card>
  );
}
