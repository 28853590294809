import { Button } from "@ameelio/ui";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FacilityFeature } from "@src/api/graphql";
import { mondayMorning } from "@src/lib/Call";
import DateRangePicker from "@src/lib/DateRangePicker";
import Header from "@src/lib/Header";
import LineChart from "@src/lib/LineChart";
import PageLoader from "@src/lib/PageLoader";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import useMountEffect from "@src/lib/useMountEffect";
import { useFacilityMetrics } from "@src/pages/ActivityPage/useFacilityMetrics";
import {
  Widget,
  WidgetRow,
  WidgetRowLayout,
} from "@src/pages/ActivityPage/Widget";
import ChartWithAverage from "@src/pages/ActivityPage/widgets/ChartWithAverage";
import { SelectedFacility } from "@src/typings/Facility";
import { endOfDay, endOfYesterday, subWeeks } from "date-fns";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

type Filters = {
  scheduledStartAfter: number | undefined;
  scheduledStartBefore: number;
};

type WidgetList = {
  layout: WidgetRowLayout;
  widgets: ReactNode[];
};

type WidgetGroup = {
  id: string;
  title: string;
  rows: WidgetList[];
};

export default function ActivityNew({
  facility,
}: {
  facility: SelectedFacility;
}) {
  const facilityHasVideoCalls = facility.features.includes(
    FacilityFeature.VideoCall,
  );
  const facilityHasVoiceCalls = facility.features.includes(
    FacilityFeature.VoiceCall,
  );
  const facilityHasVisits = facility.features.includes(
    FacilityFeature.InPersonVisit,
  );
  const facilityHasConfidentialVideoCall = facility.features.includes(
    FacilityFeature.ConfidentialVideoCall,
  );
  const facilityHasConfidentialInPersonVisit = facility.features.includes(
    FacilityFeature.ConfidentialInPersonVisit,
  );
  const { t } = useTranslation();
  const ratingLabels = [
    t("Excellent"),
    t("Great"),
    t("Good"),
    t("Bad"),
    t("Terrible"),
  ];
  const startOfWeek = mondayMorning(new Date());
  const defaultReportStart = subWeeks(startOfWeek, 4);

  const [filters, setFilters] = useState<Filters>({
    scheduledStartAfter: defaultReportStart.getTime(),
    scheduledStartBefore: endOfYesterday().getTime(),
  });

  const [hasFilterChanged, setHasFilterChanged] = useState(false);

  const reportStart =
    filters.scheduledStartAfter || defaultReportStart.getTime();
  const reportEnd = filters.scheduledStartBefore || endOfYesterday().getTime();

  const {
    fetchMetrics,
    loading,
    data,
    videoCalls,
    voiceCalls,
    inPersonVisits,
  } = useFacilityMetrics({
    facilityId: facility.id,
  });

  // Load data only on initial component mount
  useMountEffect(() => {
    void fetchMetrics({
      reportStart,
      reportEnd,
    });
  });

  const handleRefresh = () => {
    void fetchMetrics({
      reportStart,
      reportEnd,
    });
    setHasFilterChanged(false);
  };

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
    setHasFilterChanged(true);
  };

  const videoCallWidgetGroup: WidgetGroup | undefined =
    videoCalls && (facilityHasVideoCalls || facilityHasConfidentialVideoCall)
      ? {
          id: "video-calls",
          title: t("Video calls"),
          rows: [
            {
              layout: WidgetRowLayout.TWO_THIRD,
              widgets: [
                <Widget key="chart" title={t("Events per day")}>
                  <LineChart
                    label={t("# calls")}
                    labels={Object.keys(videoCalls.volume)}
                    data={Object.values(videoCalls.volume)}
                  />
                </Widget>,
                <Stack key="stats" spacing={2}>
                  <Widget title={t("Total")} value={videoCalls.total} />
                  <Widget
                    title={t("Adoption rate")}
                    value={`${videoCalls.adoptionRate}%`}
                    explanation={t(
                      "Percentage of current residents participating in video calls over the selected period.",
                    )}
                  />
                </Stack>,
              ],
            },
            {
              layout: WidgetRowLayout.HALF,
              widgets: [
                videoCalls.userRatings ? (
                  <ChartWithAverage
                    key="visitor-ratings"
                    title={t("Visitor Ratings")}
                    data={videoCalls.userRatings.ratings}
                    labels={ratingLabels}
                    average={videoCalls.userRatings.average}
                  />
                ) : undefined,
                videoCalls.inmateRatings ? (
                  <ChartWithAverage
                    key="resident-ratings"
                    title={t("Resident Ratings")}
                    data={videoCalls.inmateRatings.ratings}
                    labels={ratingLabels}
                    average={videoCalls.inmateRatings.average}
                  />
                ) : undefined,
              ].filter((i) => i !== undefined),
            },
          ],
        }
      : undefined;

  const inPersonVisitWidgetGroup: WidgetGroup | undefined =
    inPersonVisits &&
    (facilityHasVisits || facilityHasConfidentialInPersonVisit)
      ? {
          id: "in-person-visits",
          title: t("In-person visits"),
          rows: [
            {
              layout: WidgetRowLayout.TWO_THIRD,
              widgets: [
                <Widget key="chart" title={t("Events per day")}>
                  <LineChart
                    label={t("# calls")}
                    labels={Object.keys(inPersonVisits.volume)}
                    data={Object.values(inPersonVisits.volume)}
                  />
                </Widget>,
                <Stack key="stats" spacing={2}>
                  <Widget title={t("Total")} value={inPersonVisits.total} />
                  <Widget
                    title={t("Adoption rate")}
                    value={`${inPersonVisits.adoptionRate}%`}
                    explanation={t(
                      "Percentage of current residents participating in in-person visits over the selected period.",
                    )}
                  />
                </Stack>,
              ],
            },
          ],
        }
      : undefined;

  const voiceCallWidgetGroup: WidgetGroup | undefined =
    voiceCalls && facilityHasVoiceCalls
      ? {
          id: "voice-calls",
          title: t("Voice calls"),
          rows: [
            {
              layout: WidgetRowLayout.TWO_THIRD,
              widgets: [
                <Widget key="chart" title={t("Events per day")}>
                  <LineChart
                    label={t("# calls")}
                    labels={Object.keys(voiceCalls.volume)}
                    data={Object.values(voiceCalls.volume)}
                  />
                </Widget>,
                <Stack key="stats" spacing={2}>
                  <Widget title={t("Total")} value={voiceCalls.total} />
                  <Widget
                    title={t("Adoption rate")}
                    value={`${voiceCalls.adoptionRate}%`}
                  />
                </Stack>,
              ],
            },
            {
              layout: WidgetRowLayout.HALF,
              widgets: [
                voiceCalls.userRatings ? (
                  <ChartWithAverage
                    key="visitor-ratings"
                    title={t("Visitor Ratings")}
                    data={voiceCalls.userRatings.ratings}
                    labels={ratingLabels}
                    average={voiceCalls.userRatings.average}
                  />
                ) : undefined,
                voiceCalls.inmateRatings ? (
                  <ChartWithAverage
                    key="resident-ratings"
                    title={t("Resident Ratings")}
                    data={voiceCalls.inmateRatings.ratings}
                    labels={ratingLabels}
                    average={voiceCalls.inmateRatings.average}
                  />
                ) : undefined,
              ].filter((i) => i !== undefined),
            },
          ],
        }
      : undefined;

  const widgetGroups: WidgetGroup[] = [
    videoCallWidgetGroup,
    voiceCallWidgetGroup,
    inPersonVisitWidgetGroup,
  ].filter((i) => i !== undefined);

  return (
    <>
      <Header title={t("Activity overview")}>
        <ResponsiveColumns>
          <DateRangePicker
            onStartDateChange={(date) => {
              changeFilter({
                scheduledStartAfter: date || undefined,
              });
            }}
            onEndDateChange={(date) => {
              const newDate = date ? endOfDay(date) : new Date();
              changeFilter({
                scheduledStartBefore: newDate.getTime(),
              });
            }}
            disabled={loading}
            startDate={filters.scheduledStartAfter || null}
            endDate={filters.scheduledStartBefore || null}
          />
          <Box>
            <Button
              variant={hasFilterChanged ? "contained" : "outlined"}
              onClick={handleRefresh}
              startIcon={<RefreshIcon />}
              disabled={loading || !data}
            >
              {hasFilterChanged ? t("Apply filters") : t("Refresh")}
            </Button>
          </Box>
        </ResponsiveColumns>
      </Header>

      {data ? (
        <Stack
          direction={{
            xs: "column",
            lg: "row",
          }}
          spacing={2}
          margin={3}
        >
          <Stack
            flex={1}
            spacing={2}
            order={{
              xs: 1,
              lg: 0,
            }}
          >
            {widgetGroups.map((group) => (
              <Box
                key={group.title}
                id={group.id || group.title.toLowerCase().replace(/\s/gi, "-")}
              >
                <Typography variant="h2" mb={1}>
                  {group.title}
                </Typography>
                <Box>
                  {group.rows.map((tile, index) => (
                    <WidgetRow key={index} layout={tile.layout}>
                      {tile.widgets}
                    </WidgetRow>
                  ))}
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
      ) : (
        <PageLoader />
      )}
    </>
  );
}
