import { LockOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import {
  Entitlement,
  MeetingStatus,
  MeetingType,
  SecurityFeature,
} from "@src/api/graphql";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import Header from "@src/lib/Header";
import NotAllowed from "@src/lib/NotAllowed";
import PageLoader from "@src/lib/PageLoader";
import Result from "@src/lib/Result";
import { formatDate } from "@src/lib/Time";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import {
  GetCallWithRecordingDocument,
  GetCallWithRecordingQuery,
} from "@src/pages/ArtifactsPage/GetCallWithRecording.generated";
import {
  GetCallWithScreenshotsDocument,
  GetCallWithScreenshotsQuery,
} from "@src/pages/ArtifactsPage/GetCallWithScreenshots.generated";
import InCallChatModule from "@src/pages/ArtifactsPage/modules/InCallChatModule";
import RecordingModule from "@src/pages/ArtifactsPage/modules/RecordingModule";
import ScreenshotsModule from "@src/pages/ArtifactsPage/modules/ScreenshotsModule";
import TranscriptModule from "@src/pages/ArtifactsPage/modules/TranscriptModule";
import ReportProblemWithArtifactButton from "@src/pages/ArtifactsPage/ReportProblemWithArtifactButton";
import {
  GetMeetingDetailsDocument,
  GetMeetingDetailsQuery,
} from "@src/pages/MeetingDetailsPage/GetMeetingDetails.generated";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type MeetingInfo = GetMeetingDetailsQuery["meeting"];

type MeetingInfoProps = {
  modules: string[];
  canReviewRecords: boolean;
  query:
    | typeof GetCallWithScreenshotsDocument
    | typeof GetCallWithRecordingDocument;
  expirationDate?: number | null;
};

enum RecordModule {
  Recordings = "recordings",
  InCallChat = "in-call-chat",
  Screenshots = "screenshots",
  Transcript = "transcript",
}

function useMeetingInfo(meeting?: MeetingInfo): MeetingInfoProps {
  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);
  const canReviewScreenshots = useEntitlement(Entitlement.ReviewScreenshots);
  // TODO FIX In a future moment where also transcripts may be a security feature, we'll need to refactor this.
  if (meeting?.security.includes(SecurityFeature.Pictures)) {
    return {
      modules: canReviewScreenshots ? [RecordModule.Screenshots] : [],
      canReviewRecords: canReviewScreenshots,
      query: GetCallWithScreenshotsDocument,
      expirationDate: meeting.call?.screenshotsExpireAt,
    };
  } else if (meeting?.security.includes(SecurityFeature.Recording)) {
    return {
      modules: [
        RecordModule.Recordings,
        RecordModule.InCallChat,
        RecordModule.Transcript,
      ],
      canReviewRecords: canReviewRecordings,
      query: GetCallWithRecordingDocument,
      expirationDate: meeting.call?.artifactsExpireAt,
    };
  } else {
    return {
      modules: [],
      canReviewRecords: false,
      query: GetCallWithRecordingDocument,
      expirationDate: meeting?.call?.artifactsExpireAt,
    };
  }
}

function isRecordingResult(
  data: GetCallWithRecordingQuery | GetCallWithScreenshotsQuery | undefined,
): data is GetCallWithRecordingQuery {
  if (data?.meeting.call) return "recording" in data.meeting.call;
  return false;
}

function isScreenshotsResult(
  data: GetCallWithRecordingQuery | GetCallWithScreenshotsQuery | undefined,
): data is GetCallWithScreenshotsQuery {
  if (data?.meeting.call) return "screenshots" in data.meeting.call;
  return false;
}

export default function RecordsPage() {
  const { meetingId } = useParams<{ meetingId: string }>();
  if (!meetingId) throw new Error("missing meetingId param");
  const { t } = useTranslation();
  const { staffPositions } = useCurrentStaff();

  const { loading, error, data } = useQuery(GetMeetingDetailsDocument, {
    variables: { id: meetingId },
  });

  const { modules, canReviewRecords, query, expirationDate } = useMeetingInfo(
    data?.meeting,
  );

  const allowedToAccessRecords =
    canReviewRecords &&
    data?.meeting &&
    staffPositions.some((sp) => sp.facility.id === data.meeting.facility.id);

  // Get data for the appropriate query
  const recordsQuery = useQuery<
    GetCallWithScreenshotsQuery | GetCallWithRecordingQuery,
    { id: string }
  >(query, {
    skip: !allowedToAccessRecords,
    variables: {
      id: meetingId,
    },
  });
  const {
    loading: recordsLoading,
    error: recordsError,
    data: recordsData,
  } = recordsQuery;

  if (error) throw error;
  if (recordsError) throw recordsError;
  if (loading || recordsLoading || !data?.meeting) return <PageLoader />;

  const { meeting } = data;

  if (
    meeting.meetingType !== MeetingType.VideoCall &&
    meeting.meetingType !== MeetingType.VoiceCall
  ) {
    throw new Error(`${meeting.meetingType} meetings cannot have records.`);
  }

  if (!allowedToAccessRecords) {
    return (
      <Box>
        <NotAllowed
          header={
            <Header
              title={t("Not permitted")}
              subtitle={t("You do not have permission to review records.")}
            />
          }
        />
      </Box>
    );
  }

  const isExpired = expirationDate && expirationDate <= new Date().getTime();

  const meetingName = meeting.id.split("-")[0];

  return (
    <>
      <Header
        title={t("{{meetingTitle}} Records", {
          meetingTitle: t("Meeting {{name}}", { name: meetingName }),
        })}
        subtitle={
          expirationDate
            ? t(
                "Records for this meeting are available until {{date}} at {{time}}",
                {
                  date: formatDate(new Date(expirationDate), "monthdayyear"),
                  time: formatDate(new Date(expirationDate), "time"),
                },
              )
            : ""
        }
        breadcrumb={
          <Breadcrumbs
            paths={[
              {
                path: "/meetings/history",
                label: "Meetings",
              },
              {
                path: `/meetings/${meetingId}`,
                label: meetingName,
              },
              {
                path: `/meetings/${meetingId}/records`,
                label: t("Records"),
              },
            ]}
          />
        }
        showBack
        extra={
          !isExpired
            ? [
                <ReportProblemWithArtifactButton
                  artifactType={
                    modules.includes(RecordModule.Recordings)
                      ? "recording"
                      : "screenshot"
                  }
                  meetingId={meetingId}
                  key="1"
                />,
              ]
            : null
        }
      />
      {isExpired ? (
        <Result
          icon={<LockOutlined />}
          title={t("Sorry, there is no data available on this page")}
          subTitle={t("All records are expired.")}
        />
      ) : (
        <>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            p={3}
            className="printable-region"
          >
            {modules.includes(RecordModule.Recordings) &&
              isRecordingResult(recordsData) && (
                <Box flex={2}>
                  <RecordingModule meeting={recordsData.meeting} />
                </Box>
              )}
            {modules.includes(RecordModule.InCallChat) &&
              isRecordingResult(recordsData) &&
              meeting.call &&
              [
                MeetingStatus.Live,
                MeetingStatus.Ended,
                MeetingStatus.Terminated,
                MeetingStatus.NoShow,
              ].includes(meeting.status) && (
                <Box flex={1}>
                  <InCallChatModule
                    meeting={meeting}
                    canReviewRecordings={canReviewRecords}
                    allowedToAccessRecordings={allowedToAccessRecords}
                    loading={!recordsData}
                    messages={
                      recordsData.meeting.call?.messages.edges.map(
                        (e) => e.node,
                      ) || []
                    }
                  />
                </Box>
              )}
            {modules.includes(RecordModule.Screenshots) &&
              isScreenshotsResult(recordsData) && (
                <Box flex={1}>
                  {recordsData.meeting.call?.screenshots && (
                    <ScreenshotsModule
                      meetingId={meetingId}
                      screenshots={recordsData.meeting.call.screenshots}
                    />
                  )}
                </Box>
              )}
          </Stack>
          {modules.includes(RecordModule.Transcript) &&
            isRecordingResult(recordsData) &&
            recordsData.meeting.call?.transcript?.content && (
              <Stack px={3} pb={3}>
                <Box flex={1}>
                  <TranscriptModule
                    meeting={recordsData.meeting}
                    transcriptStatus={
                      recordsData.meeting.call.transcript.status
                    }
                    transcriptContent={
                      recordsData.meeting.call.transcript.content
                    }
                  />
                </Box>
              </Stack>
            )}
        </>
      )}
    </>
  );
}
