import { t } from "i18next";

export enum ProfessionalRelationship {
  Caseworker = "CASEWORKER",
  Clergy = "CLERGY",
  Employer = "EMPLOYER",
  LegalAdvisor = "LEGAL_ADVISOR",
  Mentor = "MENTOR",
  Other = "OTHER",
}
export enum PersonalRelationship {
  Mother = "MOTHER",
  Father = "FATHER",
  Daughter = "DAUGHTER",
  Son = "SON",
  Sister = "SISTER",
  Brother = "BROTHER",
  Grandparent = "GRANDPARENT",
  Wife = "WIFE",
  Husband = "HUSBAND",
  SignificantOther = "SIGNIFICANT_OTHER",
  Aunt = "AUNT",
  Uncle = "UNCLE",
  Nephew = "NEPHEW",
  Niece = "NIECE",
  Cousin = "COUSIN",
  Stepfather = "STEPFATHER",
  Stepmother = "STEPMOTHER",
  Friend = "FRIEND",
  Other = "OTHER",
}

// Here we list those relationship that we manage (e.g. for organization members...) but don't want to show in the list.
export enum HiddenRelationship {
  Educator = "EDUCATOR",
  HealthcareProfessional = "HEALTHCARE_PROFESSIONAL",
  FatherInLaw = "FATHER-IN-LAW",
  MotherInLaw = "MOTHER-IN-LAW",
  SonInLaw = "SON-IN-LAW",
  DaughterInLaw = "DAUGHTER-IN-LAW",
  BrotherInLaw = "BROTHER-IN-LAW",
  SisterInLaw = "SISTER-IN-LAW",
}

export type Relationship =
  | PersonalRelationship
  | ProfessionalRelationship
  | HiddenRelationship;

export type ConnectionRelationship = Relationship | string;

export const relationshipToHumanReadable = (
  relationship: ConnectionRelationship,
) => {
  const relationshipTypes: Record<ConnectionRelationship, string> = {
    [PersonalRelationship.Aunt]: t("Aunt"),
    [PersonalRelationship.Brother]: t("Brother"),
    [PersonalRelationship.Cousin]: t("Cousin"),
    [PersonalRelationship.Daughter]: t("Daughter"),
    [PersonalRelationship.Father]: t("Father"),
    [PersonalRelationship.Friend]: t("Friend"),
    [PersonalRelationship.Grandparent]: t("Grandparent"),
    [PersonalRelationship.Husband]: t("Husband"),
    [PersonalRelationship.Mother]: t("Mother"),
    [PersonalRelationship.Nephew]: t("Nephew"),
    [PersonalRelationship.Niece]: t("Niece"),
    [PersonalRelationship.SignificantOther]: t("Significant Other"),
    [PersonalRelationship.Sister]: t("Sister"),
    [PersonalRelationship.Son]: t("Son"),
    [PersonalRelationship.Stepfather]: t("Stepfather"),
    [PersonalRelationship.Stepmother]: t("Stepmother"),
    [PersonalRelationship.Uncle]: t("Uncle"),
    [PersonalRelationship.Wife]: t("Wife"),

    [HiddenRelationship.BrotherInLaw]: t("Brother-in-law"),
    [HiddenRelationship.SisterInLaw]: t("Sister-in-law"),
    [HiddenRelationship.DaughterInLaw]: t("Daughter-in-law"),
    [HiddenRelationship.FatherInLaw]: t("Father-in-law"),
    [HiddenRelationship.MotherInLaw]: t("Mother-in-law"),
    [HiddenRelationship.SonInLaw]: t("Son-in-law"),

    [ProfessionalRelationship.Caseworker]: t("Caseworker"),
    [ProfessionalRelationship.Clergy]: t("Clergy"),
    [ProfessionalRelationship.Employer]: t("Employer"),
    [ProfessionalRelationship.LegalAdvisor]: t("Legal advisor"),
    [ProfessionalRelationship.Mentor]: t("Mentor"),
    [ProfessionalRelationship.Other]: t("Other"),

    [HiddenRelationship.Educator]: t("Educator"),
    [HiddenRelationship.HealthcareProfessional]: t("Healthcare Professional"),
  };
  const key = (relationship || "").toUpperCase();
  return relationshipTypes[key] || relationship;
};
