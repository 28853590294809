import {
  Meeting,
  MeetingStatus,
  MeetingType,
  SecurityFeature,
} from "@src/api/graphql";

export default function mayHaveScreenshots(
  meeting: Pick<Meeting, "meetingType" | "security" | "status">,
) {
  return (
    meeting.security.includes(SecurityFeature.Pictures) &&
    meeting.meetingType === MeetingType.VideoCall &&
    [
      MeetingStatus.Ended,
      MeetingStatus.Terminated,
      MeetingStatus.NoShow,
    ].includes(meeting.status)
  );
}
