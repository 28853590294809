import { Button, ContextMenu } from "@ameelio/ui";
import {
  Box,
  Card,
  Chip,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Entitlement } from "@src/api/graphql";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ConditionalTooltip from "../../lib/ConditionalTooltip";
import AddEditKeywordModal from "./AddEditKeywordModal";
import DeleteKeywordModal from "./DeleteKeywordModal";
import { GetMessageKeywordsQuery } from "./GetMessageKeywords.generated";

export type Keyword = GetMessageKeywordsQuery["facility"]["keywords"][0];

type Props = {
  keywords: Keyword[];
};

type KeywordRowProps = {
  keyword: Keyword;
};

function toCapitalizedCase(s: string) {
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

function KeywordRow({ keyword }: KeywordRowProps) {
  const [isEditingKeyword, setIsEditingKeyword] = useState(false);
  const [isDeletingKeyword, setIsDeletingKeyword] = useState(false);
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <TableCell>{keyword.name}</TableCell>
        <TableCell>
          <Chip label={toCapitalizedCase(keyword.category)} />
        </TableCell>
        <TableCell>{keyword.description}</TableCell>
        <TableCell>{keyword.spellings.join(", ")}</TableCell>

        <TableCell>
          <ContextMenu id={`${keyword.id}-actions`}>
            {canEdit
              ? [
                  <MenuItem
                    key="edit"
                    onClick={() => {
                      setIsEditingKeyword(true);
                    }}
                  >
                    {t("Edit keyword")}
                  </MenuItem>,
                  <MenuItem
                    key="delete"
                    onClick={() => {
                      setIsDeletingKeyword(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.error.main,
                    }}
                  >
                    {t("Delete keyword")}
                  </MenuItem>,
                ]
              : [
                  <MenuItem
                    key="details"
                    onClick={() => {
                      setIsEditingKeyword(true);
                    }}
                  >
                    {t("Details")}
                  </MenuItem>,
                ]}
          </ContextMenu>
        </TableCell>
      </TableRow>

      {isEditingKeyword && (
        <AddEditKeywordModal
          keyword={keyword}
          onClose={() => setIsEditingKeyword(false)}
        />
      )}
      {isDeletingKeyword && (
        <DeleteKeywordModal
          keyword={keyword}
          onClose={() => setIsDeletingKeyword(false)}
        />
      )}
    </>
  );
}

export default function KeywordsTable({ keywords }: Props) {
  const [isAddingKeyword, setIsAddingKeyword] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const foundKeywords = useMemo(() => {
    return keywords.filter(
      (k) =>
        k.isActive &&
        [k.name]
          .concat(k.spellings)
          .some((s) => s.toLowerCase().includes(search.toLowerCase())),
    );
  }, [keywords, search]);
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  return (
    <>
      <Card variant="outlined" sx={{ padding: 3, borderRadius: "8px" }}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Box>
            <SectionTitle>{t("Keyword management")}</SectionTitle>
          </Box>
          <ConditionalTooltip
            active={!canEdit}
            title={t("You do not have permission to add keywords.")}
          >
            <Button
              onClick={() => {
                setIsAddingKeyword(true);
              }}
              disabled={!canEdit}
              variant="outlined"
            >
              {t("Add keyword")}
            </Button>
          </ConditionalTooltip>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {t(
              "If a message contains one of these words it will be flagged for review before being sent.",
            )}
          </Typography>
        </Box>
        <Box sx={{ mb: 1 }}>
          <TextField
            label={t("Search")}
            fullWidth
            onChange={(event) => {
              setSearch(event.target.value);
              setPage(0);
            }}
          />
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Keyword")}</TableCell>
                <TableCell>{t("Tag")}</TableCell>
                <TableCell>{t("Comments")}</TableCell>
                <TableCell>{t("Alternate spellings")}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {foundKeywords
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((k) => (
                  <KeywordRow keyword={k} key={k.id} />
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={5}
                  count={foundKeywords.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(
                    _: React.MouseEvent<HTMLButtonElement> | null,
                    newPage: number,
                  ) => setPage(newPage)}
                  onRowsPerPageChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => {
                    setRowsPerPage(Number(event.target.value));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
      {isAddingKeyword && (
        <AddEditKeywordModal onClose={() => setIsAddingKeyword(false)} />
      )}
    </>
  );
}
