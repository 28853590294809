import { sortByStrings } from "@ameelio/core";
import { useQuery } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import { MeetingType } from "@src/api/graphql";
import featureEnabled from "@src/lib/featureEnabled";
import Header from "@src/lib/Header";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { useTranslation } from "react-i18next";
import { GetFacilityGroupsAndKiosksDocument } from "../../lib/ScheduleTable/AddEditScheduleModal/GetFacilityGroupsAndKiosks.generated";
import KiosksTable from "./KiosksTable";
import NewKiosksTable from "./NewKiosksTable";

export default function FacilityKioskSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();

  const { data: groupsKiosksData, error } = useQuery(
    GetFacilityGroupsAndKiosksDocument,
    {
      variables: {
        facilityId: facility.id,
      },
    },
  );

  if (error) throw error;
  if (!groupsKiosksData) return <SettingsSkeleton />;

  const videoKiosks = sortByStrings(
    groupsKiosksData.facility.kiosks.filter(
      (k) => k.meetingType === MeetingType.VideoCall,
    ),
    (r) => r.name,
  );
  const ipvKiosks = sortByStrings(
    groupsKiosksData.facility.kiosks.filter(
      (k) => k.meetingType === MeetingType.InPersonVisit,
    ),
    (r) => r.name,
  );

  return (
    <>
      <Header
        title={t("Meeting resources")}
        subtitle={t(
          "Reservable resources for in-person visits or video calls.",
        )}
      />
      {featureEnabled("SCHEDULES_PAGE") ? (
        <Box p={2}>
          <NewKiosksTable
            kiosks={sortByStrings(
              groupsKiosksData.facility.kiosks,
              (r) => r.name,
            )}
          />
        </Box>
      ) : (
        <Stack spacing={2} p={2}>
          <KiosksTable kiosks={videoKiosks} isInPerson={false} />
          <KiosksTable kiosks={ipvKiosks} isInPerson />
        </Stack>
      )}
    </>
  );
}
