import { ContextMenu } from "@ameelio/ui";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Entitlement, Kiosk as GqlKiosk } from "@src/api/graphql";
import ScreenSection from "@src/lib/ScreenSection";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConditionalTooltip from "../../lib/ConditionalTooltip";
import DisableEnableKioskModal from "./DisableEnableKioskModal";
import NewAddEditKioskModal from "./NewAddEditKioskModal";

type Kiosk = Pick<
  GqlKiosk,
  "id" | "name" | "enabled" | "capacity" | "meetingType"
>;
type Props = {
  kiosks: Kiosk[];
};

type KioskRowProps = {
  kiosk: Kiosk;
};

function KioskRow({ kiosk }: KioskRowProps) {
  const [editingKiosk, setEditingKiosk] = useState(false);
  const [disablingKiosk, setDisablingKiosk] = useState(false);
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{kiosk.name}</Typography>
        </TableCell>
        <TableCell style={{ width: "0px" }}>
          <Typography variant="body2">
            {kiosk.enabled ? t("Enabled") : t("Disabled")}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 3 }}>
          <ContextMenu id={`${kiosk.id}-actions`}>
            {canEdit
              ? [
                  <MenuItem
                    key="edit"
                    onClick={() => {
                      setEditingKiosk(true);
                    }}
                  >
                    {t("Edit resource")}
                  </MenuItem>,
                  <MenuItem
                    key="manage"
                    onClick={() => {
                      setDisablingKiosk(true);
                    }}
                    sx={
                      kiosk.enabled
                        ? { color: (theme) => theme.palette.error.main }
                        : {}
                    }
                  >
                    {kiosk.enabled
                      ? t("Disable resource")
                      : t("Enable resource")}
                  </MenuItem>,
                ]
              : [
                  <MenuItem
                    key="details"
                    onClick={() => {
                      setEditingKiosk(true);
                    }}
                  >
                    {t("Details")}
                  </MenuItem>,
                ]}
          </ContextMenu>
        </TableCell>
      </TableRow>
      {disablingKiosk && (
        <DisableEnableKioskModal
          kiosk={kiosk}
          onClose={() => setDisablingKiosk(false)}
        />
      )}
      {editingKiosk && (
        <NewAddEditKioskModal
          kiosk={kiosk}
          onClose={() => setEditingKiosk(false)}
        />
      )}
    </>
  );
}

export default function KiosksTable({ kiosks }: Props) {
  const [isAddingKiosk, setIsAddingKiosk] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  return (
    <ScreenSection
      title={t("Resources")}
      titlePosition="inner"
      extra={
        <ConditionalTooltip
          active={!canEdit}
          title={t("You do not have permission to add meeting resources.")}
        >
          <Button
            onClick={() => {
              setIsAddingKiosk(true);
            }}
            disabled={!canEdit}
            variant="outlined"
          >
            {t("Add resource")}
          </Button>
        </ConditionalTooltip>
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Name")}</TableCell>
              <TableCell style={{ width: "0px" }}>{t("Status")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {kiosks
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((k) => (
                <KioskRow kiosk={k} key={k.id} />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ border: "none" }}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={kiosks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(
                  _: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number,
                ) => setPage(newPage)}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => {
                  setRowsPerPage(Number(event.target.value));
                  setPage(0);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {isAddingKiosk && (
        <NewAddEditKioskModal onClose={() => setIsAddingKiosk(false)} />
      )}
    </ScreenSection>
  );
}
