import { Button } from "@ameelio/ui";
import { SystemUser, Visitor, VisitorIdentity } from "@src/api/graphql";
import { isMinor } from "@src/lib/Common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RejectVisitorIDDialog from "./RejectVisitorIDDialog";
import VisitorPhotosModal from "./VisitorPhotosModal";

type Props = {
  visitor: Pick<Visitor, "id" | "fullName" | "dateOfBirth" | "pstnNumber"> & {
    systemRelationship: Pick<
      SystemUser,
      "id" | "status" | "statusDetails"
    > | null;
    identity: Pick<VisitorIdentity, "id"> | null;
  };
};

export default function VisitorIDButton({ visitor }: Props) {
  const [showPhotos, setShowPhotos] = useState(false);
  const [showRejectIDConfirmation, setShowRejectIDConfirmation] =
    useState(false);
  const { t } = useTranslation();

  return (
    <>
      {showPhotos && (
        <VisitorPhotosModal
          visitor={visitor}
          onCancel={() => setShowPhotos(false)}
          onReject={() => {
            setShowRejectIDConfirmation(true);
          }}
        />
      )}
      {showRejectIDConfirmation && visitor.systemRelationship?.id && (
        <RejectVisitorIDDialog
          systemVisitorId={visitor.systemRelationship.id}
          onClose={() => {
            setShowRejectIDConfirmation(false);
          }}
          onSuccess={() => {
            setShowRejectIDConfirmation(false);
            setShowPhotos(false);
          }}
        />
      )}
      <Button
        disabled={!visitor.identity}
        disabledReason={
          isMinor(visitor)
            ? t("This user is a minor and has no photo ID")
            : visitor.pstnNumber
              ? t("This user does not have an account to manage their ID")
              : t("This user has no photo ID")
        }
        onClick={() => setShowPhotos(true)}
        variant="outlined"
      >
        {t("See Photo ID")}
      </Button>
    </>
  );
}
