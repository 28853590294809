import { MeetingType } from "@src/api/graphql";
import featureEnabled from "@src/lib/featureEnabled";
import CallRegistry from "@src/lib/LiveCall/CallRegistry";
import NotFoundSplashScreen from "@src/lib/NotFoundSplashScreen";
import SessionBoundary from "@src/lib/SessionBoundary";
import LogoutScreen from "@src/LogoutScreen";
import ActivityPage from "@src/pages/ActivityPage";
import RecordsPage from "@src/pages/ArtifactsPage/RecordsPage";
import ConfidentialInPersonVisitsSettingsPage from "@src/pages/ConfidentialInPersonVisitsSettingsPage";
import NewEventSchedulePage from "@src/pages/ScheduleEventPage/NewEventSchedulePage";
import { Navigate, Outlet, RouteObject, useParams } from "react-router-dom";
import ConfidentialVideoCallsSettingsPage from "./ConfidentialVideoCallSettingsPage";
import ConnectionRequestsPage from "./ConnectionRequestsPage";
import DailySchedulePage from "./DailySchedulePage";
import DashboardPage from "./DashboardPage";
import FacilityKioskSettingsPage from "./FacilityKioskSettingsPage";
import FacilityLayout from "./FacilityLayout";
import FacilityNetworkSettingsPage from "./FacilityNetworkSettingsPage";
import FacilitySettingsPage from "./FacilitySettingsPage";
import InmatePage from "./InmatePage";
import InmatesPage from "./InmatesPage";
import InPersonVisitsSettingsPage from "./InPersonVisitsSettingsPage";
import NewInPersonVisitsSettingsPage from "./InPersonVisitsSettingsPage/newPage";
import LiveVideoCallsPage from "./LiveCallPage";
import LiveVoiceCallMonitoringPage from "./LiveVoiceCallMonitoringPage";
import LiveWebinarPage from "./LiveWebinarPage";
import LiveWebinarsPage from "./LiveWebinarsPage";
import LoginPage from "./LoginPage";
import MeetingDetailsPage from "./MeetingDetailsPage";
import MeetingRequestsPage from "./MeetingRequestsPage";
import MeetingsHistoryPage from "./MeetingsHistoryPage";
import MessageReviewPage from "./MessageReviewPage";
import MessagesSettingsPage from "./MessagesSettingsPage";
import OrganizationPage from "./OrganizationPage";
import OrganizationsPage from "./OrganizationsPage";
import PhoneCallsSettingsPage from "./PhoneCallsSettingsPage";
import { EditScheduleEventPage } from "./ScheduleEventPage/EditScheduleEvent";
import NewInmateEventSchedulePage from "./ScheduleEventPage/NewInmateEventSchedulePage";
import SchedulesPage from "./SchedulesPage";
import SearchTranscriptsPage from "./SearchTranscriptsPage";
import SettingsLayout from "./SettingsLayout";
import StaffPreferencesPage from "./StaffPreferencesPage";
import StaffSupportPage from "./StaffSupportPage";
import SystemLayout from "./SystemLayout";
import SystemFacilitiesPage from "./SystemPage/SystemFacilitiesPage";
import SystemStaffPage from "./SystemPage/SystemStaffPage";
import SystemStaffDetailPage from "./SystemStaffDetailPage";
import UpcomingMeetingsPage from "./UpcomingMeetingsPage";
import VideoCallsSettingsPage from "./VideoCallSettingsPage";
import NewVideoCallsSettingsPage from "./VideoCallSettingsPage/newPage";
import VisitorPage from "./VisitorPage";
import VisitorsPage from "./VisitorsPage";
import VoiceCallsSettingsPage from "./VoiceCallsSettingsPage";
import NewVoiceCallsSettingsPage from "./VoiceCallsSettingsPage/newPage";
import WebinarsSettingsPage from "./WebinarsSettingsPage";

export type Path =
  | "/activity"
  | "/login"
  | "/phone-calls"
  | "/logout"
  | "/help"
  | `/connection-requests/${string}`
  | `/call-requests/${string}`
  | `/meeting-requests/${string}`
  | `/screenshots/${string}`
  | `/recording/${string}`
  | `/records/${string}`
  | "/inmates"
  | `/inmates/${string}`
  | `/visitors/${string}`
  | "/visitations" // aka monitoring live calls
  | "/live/video-calls"
  | "/live/voice-calls"
  | "/live/webinars"
  | `/live/webinars/${string}`
  | "/meetings/upcoming"
  | "/meetings/history"
  | "/meetings/search-transcripts"
  | `/meetings/${string}`
  | "/visitors"
  | "/message-review"
  | "/live-voice-calls"
  | "/organizations"
  | "/organizations/pending"
  | `/organizations/${string}`
  | "/preferences"
  | "/system"
  | `/system/staff/${string}`
  | "/settings"
  | "/settings/facility"
  | "/settings/schedules"
  | "/settings/confidential-in-person-visits"
  | "/settings/in-person-visits"
  | "/settings/voice-calls"
  | "/settings/kiosks"
  | "/settings/messages"
  | "/settings/network"
  | "/settings/video-calls"
  | "/settings/phone-calls"
  | "/settings/confidential-video-calls"
  | "/settings/webinars"
  | "/system/facilities"
  | "/system/staff"
  | "/"; // index

const _MenuTabs = [
  //
  "activity",
  "dashboard",
  // monitor
  "live-video-calls",
  "live-voice-calls",
  "live-webinars",
  "meetings-upcoming",
  "meetings-daily",
  "meetings-history",
  "search-transcripts",
  // review
  "visitor-meeting-requests",
  "visitor-connection-requests",
  "visitor-pending-messages",
  "professional-meeting-requests",
  "professional-connection-requests",
  "organization-requests",
  // directory
  "inmates",
  "visitors",
  "professional-partners",
  // system
  "system-facilities",
  "system-staff",
  //
  "settings",
  "help",
  //
  "none",
  "settings-facility",
  "settings-schedules",
  "settings-kiosks",
  "settings-phone-calls",
  "settings-confidential-in-person-visits",
  "settings-in-person-visits",
  "settings-network",
  "settings-video-calls",
  "settings-confidential-video-calls",
  "settings-webinars",
  "settings-voice-calls",
  "settings-messages",
  "staff-preferences",
] as const;
export type MenuTab = (typeof _MenuTabs)[number];

const MemberRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/inmates/${id}`} />;
};

const ContactRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/visitors/${id}`} />;
};

const RecordsRedirect = () => {
  const { meetingId } = useParams();
  return <Navigate to={`/records/${meetingId}`} />;
};

export const routes: RouteObject[] = [
  {
    path: "*",
    element: <NotFoundSplashScreen />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
  {
    element: (
      <SessionBoundary>
        <CallRegistry
          meetingTypes={[MeetingType.VideoCall, MeetingType.VoiceCall]}
        >
          <Outlet />
        </CallRegistry>
      </SessionBoundary>
    ),
    children: [
      {
        path: "/meetings/upcoming",
        element: (
          <FacilityLayout selectedTab="meetings-upcoming">
            <UpcomingMeetingsPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/meetings/daily",
        element: (
          <FacilityLayout selectedTab="meetings-daily">
            <DailySchedulePage />
          </FacilityLayout>
        ),
      },
      {
        path: "/meetings/history",
        element: (
          <FacilityLayout selectedTab="meetings-history">
            <MeetingsHistoryPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/meetings/search-transcripts",
        element: (
          <FacilityLayout selectedTab="search-transcripts">
            <SearchTranscriptsPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/message-review",
        element: (
          <FacilityLayout selectedTab="visitor-pending-messages">
            <MessageReviewPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/connection-requests/personal",
        element: (
          <FacilityLayout selectedTab="visitor-connection-requests">
            <ConnectionRequestsPage showProfessional={false} />
          </FacilityLayout>
        ),
      },
      {
        path: "/connection-requests/professional",
        element: (
          <FacilityLayout selectedTab="professional-connection-requests">
            <ConnectionRequestsPage showProfessional={true} />
          </FacilityLayout>
        ),
      },
      {
        path: "/call-requests/personal",
        element: <Navigate to="/meeting-requests/personal" />,
      },
      {
        path: "/meeting-requests/personal",
        element: (
          <FacilityLayout selectedTab="visitor-meeting-requests">
            <MeetingRequestsPage showProfessional={false} />
          </FacilityLayout>
        ),
      },
      {
        path: "/call-requests/professional",
        element: <Navigate to="/meeting-requests/professional" />,
      },
      {
        path: "/meeting-requests/professional",
        element: (
          <FacilityLayout selectedTab="professional-meeting-requests">
            <MeetingRequestsPage showProfessional={true} />
          </FacilityLayout>
        ),
      },
      {
        path: "/recording/:meetingId",
        element: <RecordsRedirect />,
      },
      {
        path: "/records/:meetingId",
        element: (
          <FacilityLayout selectedTab="meetings-history">
            <RecordsPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/screenshots/:meetingId",
        element: <RecordsRedirect />,
      },
      {
        path: "/inmates",
        element: (
          <FacilityLayout selectedTab="inmates">
            <InmatesPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/members",
        element: <Navigate to="/inmates" />,
      },
      {
        path: "/inmates/:id",
        element: (
          <FacilityLayout selectedTab="inmates">
            <InmatePage />
          </FacilityLayout>
        ),
      },
      {
        path: "/inmates/event/new",
        element: (
          <FacilityLayout selectedTab="inmates">
            <NewEventSchedulePage />
          </FacilityLayout>
        ),
      },
      {
        path: "/inmates/:id/event/new",
        element: (
          <FacilityLayout selectedTab="inmates">
            <NewInmateEventSchedulePage />
          </FacilityLayout>
        ),
      },
      {
        path: "/inmates/:id/event/:meetingId/edit",
        element: (
          <FacilityLayout selectedTab="inmates">
            <EditScheduleEventPage />
          </FacilityLayout>
        ),
      },
      { path: "/members/:id", element: <MemberRedirect /> },
      {
        path: "/visitors",
        element: (
          <FacilityLayout selectedTab="visitors">
            <VisitorsPage />
          </FacilityLayout>
        ),
      },
      { path: "/contacts", element: <Navigate to="/visitors" /> },
      {
        path: "/visitors/:id",
        element: (
          <FacilityLayout selectedTab="visitors">
            <VisitorPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/contacts/:id",
        element: <ContactRedirect />,
      },
      {
        path: "/visitations",
        element: <Navigate to="/live/video-calls" />,
      },
      {
        path: "/live/video-calls",
        element: (
          <FacilityLayout selectedTab="live-video-calls">
            <LiveVideoCallsPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/live/webinars",
        element: (
          <FacilityLayout selectedTab="live-webinars">
            <LiveWebinarsPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/live/webinars/:meetingId",
        element: (
          <FacilityLayout selectedTab="live-video-calls">
            <LiveWebinarPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/settings",
        element: <Navigate to="/settings/facility" />,
      },
      {
        path: "/meetings/:id",
        element: (
          <FacilityLayout selectedTab="meetings-history">
            <MeetingDetailsPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/live-voice-calls",
        element: <Navigate to="/live/voice-calls" />,
      },
      {
        path: "/live/voice-calls",
        element: (
          <FacilityLayout selectedTab="live-voice-calls">
            <LiveVoiceCallMonitoringPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/organizations",
        element: (
          <FacilityLayout selectedTab="professional-partners">
            <OrganizationsPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/organizations/:id",
        element: (
          <FacilityLayout selectedTab="professional-partners">
            <OrganizationPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/preferences",
        element: (
          <SystemLayout selectedTab="system-staff">
            <StaffPreferencesPage />
          </SystemLayout>
        ),
      },
      {
        path: "/settings/kiosks",
        element: (
          <SettingsLayout selectedTab="settings-kiosks">
            <FacilityKioskSettingsPage />
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/confidential-video-calls",
        element: (
          <SettingsLayout selectedTab="settings-confidential-video-calls">
            {featureEnabled("SCHEDULES_PAGE") ? (
              <NewVideoCallsSettingsPage />
            ) : (
              <ConfidentialVideoCallsSettingsPage />
            )}
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/video-calls",
        element: (
          <SettingsLayout selectedTab="settings-video-calls">
            {featureEnabled("SCHEDULES_PAGE") ? (
              <NewVideoCallsSettingsPage />
            ) : (
              <VideoCallsSettingsPage />
            )}
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/facility",
        element: (
          <SettingsLayout selectedTab="settings-facility">
            <FacilitySettingsPage />
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/schedules",
        element: featureEnabled("SCHEDULES_PAGE") ? (
          <SettingsLayout selectedTab="settings-schedules">
            <SchedulesPage />
          </SettingsLayout>
        ) : (
          <Navigate to="/" />
        ),
      },
      {
        path: "/settings/network",
        element: (
          <SettingsLayout selectedTab="settings-network">
            <FacilityNetworkSettingsPage />
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/in-person-visits",
        element: (
          <SettingsLayout selectedTab="settings-in-person-visits">
            {featureEnabled("SCHEDULES_PAGE") ? (
              <NewInPersonVisitsSettingsPage />
            ) : (
              <InPersonVisitsSettingsPage />
            )}
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/confidential-in-person-visits",
        element: (
          <SettingsLayout selectedTab="settings-confidential-in-person-visits">
            {featureEnabled("SCHEDULES_PAGE") ? (
              <NewInPersonVisitsSettingsPage />
            ) : (
              <ConfidentialInPersonVisitsSettingsPage />
            )}
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/voice-calls",
        element: (
          <SettingsLayout selectedTab="settings-voice-calls">
            {featureEnabled("SCHEDULES_PAGE") ? (
              <NewVoiceCallsSettingsPage />
            ) : (
              <VoiceCallsSettingsPage />
            )}
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/phone-calls",
        element: (
          <SettingsLayout selectedTab="settings-phone-calls">
            <PhoneCallsSettingsPage />
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/messages",
        element: (
          <SettingsLayout selectedTab="settings-messages">
            <MessagesSettingsPage />
          </SettingsLayout>
        ),
      },
      {
        path: "/settings/webinars",
        element: (
          <SettingsLayout selectedTab="settings-webinars">
            {featureEnabled("SCHEDULES_PAGE") ? (
              <NewVideoCallsSettingsPage />
            ) : (
              <WebinarsSettingsPage />
            )}
          </SettingsLayout>
        ),
      },
      {
        path: "/",
        element: (
          <FacilityLayout selectedTab="dashboard">
            <DashboardPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/activity",
        element: (
          <FacilityLayout selectedTab="activity">
            <ActivityPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/help",
        element: (
          <FacilityLayout selectedTab="help">
            <StaffSupportPage />
          </FacilityLayout>
        ),
      },
      {
        path: "/system",
        element: <Navigate to="/system/facilities" />,
      },
      {
        path: "/system/facilities",
        element: (
          <SystemLayout selectedTab="system-facilities">
            <SystemFacilitiesPage />
          </SystemLayout>
        ),
      },
      {
        path: "/system/staff",
        element: (
          <SystemLayout selectedTab="system-staff">
            <SystemStaffPage />
          </SystemLayout>
        ),
      },
      {
        path: "/system/staff/:id",
        element: (
          <SystemLayout selectedTab="system-staff">
            <SystemStaffDetailPage />
          </SystemLayout>
        ),
      },
    ],
  },
];
