import { FacilityFeature, MeetingType } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";

export const labelMeetingType = (
  meetingType: MeetingType,
  opt?: { titleCase?: boolean; plural?: boolean },
): string => {
  const TITLES: Record<MeetingType, string> = {
    [MeetingType.VideoCall]: i18n.t("Video Call"),
    [MeetingType.InPersonVisit]: i18n.t("In-Person Visit"),
    [MeetingType.VoiceCall]: i18n.t("Voice Call"),
    [MeetingType.Webinar]: i18n.t("Webinar"),
  };
  const PLURAL_LABELS: Record<MeetingType, string> = {
    [MeetingType.VideoCall]: i18n.t("video calls"),
    [MeetingType.InPersonVisit]: i18n.t("in-person visits"),
    [MeetingType.VoiceCall]: i18n.t("voice calls"),
    [MeetingType.Webinar]: i18n.t("webinars"),
  };
  const LABELS: Record<MeetingType, string> = {
    [MeetingType.VideoCall]: i18n.t("video call"),
    [MeetingType.InPersonVisit]: i18n.t("in-person visit"),
    [MeetingType.VoiceCall]: i18n.t("voice call"),
    [MeetingType.Webinar]: i18n.t("webinar"),
  };

  const label = LABELS[meetingType];
  return opt?.titleCase
    ? TITLES[meetingType]
    : opt?.plural
      ? PLURAL_LABELS[meetingType]
      : label;
};

// TODO revisit design here
export const meetingTypeTitle = (meetingType: MeetingType[]) => {
  const TITLES: Record<MeetingType, string> = {
    [MeetingType.VideoCall]: i18n.t("Video Call"),
    [MeetingType.InPersonVisit]: i18n.t("In-Person Visit"),
    [MeetingType.VoiceCall]: i18n.t("Voice Call"),
    [MeetingType.Webinar]: i18n.t("Webinar"),
  };
  return meetingType.map((t) => TITLES[t]).join("/");
};

const data: Record<MeetingType, FacilityFeature[]> = {
  [MeetingType.InPersonVisit]: [
    FacilityFeature.InPersonVisit,
    FacilityFeature.ConfidentialInPersonVisit,
  ],
  [MeetingType.VideoCall]: [
    FacilityFeature.VideoCall,
    FacilityFeature.ConfidentialVideoCall,
  ],
  [MeetingType.VoiceCall]: [FacilityFeature.VoiceCall],
  [MeetingType.Webinar]: [FacilityFeature.Webinar],
};
export default function featuresForMeetingType(type: MeetingType) {
  return data[type];
}
