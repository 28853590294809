import { Button, ButtonProps } from "@ameelio/ui";
import {
  Call,
  Meeting,
  MeetingStatus,
  SecurityFeature,
} from "@src/api/graphql";
import ContextMenuItem from "@src/lib/ContextMenuItem";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";

type Props = ButtonProps & {
  displayAsMenuItem?: boolean;
  meeting: Pick<Meeting, "id" | "status" | "security" | "meetingType"> & {
    call?: Pick<
      Call,
      "id" | "screenshotsExpireAt" | "artifactsExpireAt"
    > | null;
  };
};

export default function ViewRecordsButton({
  meeting,
  displayAsMenuItem,
  ...rest
}: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const now = new Date().getTime();
  let disabledReason = "";

  const expirationDate = meeting.security.includes(SecurityFeature.Pictures)
    ? meeting.call?.screenshotsExpireAt
    : meeting.call?.artifactsExpireAt;

  if (
    !meeting.call ||
    [MeetingStatus.Cancelled, MeetingStatus.Rejected].includes(meeting.status)
  ) {
    disabledReason = t("This meeting never took place.");
  } else if (expirationDate && expirationDate <= now) {
    disabledReason = t(
      "These records are no longer available. The records have expired on {{date}}.",
      { date: formatDate(expirationDate, "datetime") },
    );
  }

  return displayAsMenuItem ? (
    <ContextMenuItem
      key="view-records"
      disabled={Boolean(disabledReason) || rest.disabled}
      disabledReason={disabledReason}
      onClick={() => {
        navigate(`/records/${meeting.id}`);
      }}
    >
      {t("View records")}
    </ContextMenuItem>
  ) : (
    <Button
      variant="outlined"
      disabled={Boolean(disabledReason) || rest.disabled}
      disabledReason={disabledReason}
      onClick={() => {
        navigate(`/records/${meeting.id}`);
      }}
      {...rest}
    >
      {t("View records")}
    </Button>
  );
}
