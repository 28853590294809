import {
  Meeting,
  MeetingStatus,
  MeetingType,
  SecurityFeature,
} from "@src/api/graphql";

export default function mayHaveRecording(
  meeting: Pick<Meeting, "meetingType" | "security" | "status">,
) {
  return (
    meeting.security.includes(SecurityFeature.Recording) &&
    (meeting.meetingType === MeetingType.VideoCall ||
      meeting.meetingType === MeetingType.VoiceCall) &&
    [
      MeetingStatus.NoShow,
      MeetingStatus.Ended,
      MeetingStatus.Terminated,
    ].includes(meeting.status)
  );
}
