import { differenceInYears, subYears } from "date-fns";
import { TOptions } from "i18next";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
  Validate,
} from "react-hook-form";
import i18n from "../i18n/i18nConfig";
import { inferDateFormat, monthDateYearFormat } from "./timeFormats";

const { t } = i18n;
const MINOR_AGE_YEARS_CUTOFF = 18;

type Rules = Omit<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled" | "validate"
> & {
  // require validate functions to have key names for easier merging
  validate?: Record<
    string,
    Validate<FieldPathValue<FieldValues, FieldPath<FieldValues>>, FieldValues>
  >;
};

// utility type to ensure that only strings are returned from translation
const tString = (key: string, options?: TOptions) => {
  const result = t(key, options);
  if (typeof result === "string") return result;
  return undefined;
};

export function mergeRules(...rules: Rules[]): Rules {
  return rules.reduce(
    (acc, rule) => ({
      ...acc,
      ...rule,
      validate: { ...acc.validate, ...rule.validate },
    }),
    {},
  );
}

/**
 * isRequired marks a field as required. This should result in creating an
 * aria-required attribute on the input field in addition to adding validation
 * logic.
 *
 * @param message should mention the field name, like "Email is required"
 */
export function isRequired(message: string): Rules {
  return {
    required: {
      value: true,
      message,
    },
  };
}

/**
 * Not explicitly necessary, but it is a good idea to have a function that clarifies that.
 */
export function isNotRequired(): Rules {
  return {
    required: false,
  };
}

/**
 * isRequiredBoolean is used for checkboxes that are required to be selected.
 *
 * @param message should mention the field name, like "Please accept the terms of use."
 */
export function isRequiredBoolean(message: string): Rules {
  return {
    validate: {
      requiredTrue: (value) => Boolean(value) || message,
    },
  };
}

export function isRequiredLength(message: string): Rules {
  return {
    validate: {
      requiredLength: (value: string) =>
        value && value.length ? true : message,
    },
  };
}

/**
 * Why? Should this be standard?
 */
export function isRequiredString(message: string): Rules {
  return {
    required: {
      value: true,
      message,
    },
    validate: {
      extraRequired: (v: string) => Boolean(v && v.trim()) || message,
    },
  };
}

/**
 * hasMinLength is shorthand for specifying only a minimum length requirement.
 *
 * @param message should mention the field name, like "Password should be at least 6 characters long."
 */
export function hasMinLength(minLength: number, message: string): Rules {
  return {
    minLength: {
      value: minLength,
      message,
    },
  };
}

/**
 * hasMinValue is shorthand for specifying only a minimum value requirement.
 * This is useful for number fields.
 * @param message should mention the field name, like "Age must be at least 13."
 */
export function hasMinValue(minValue: number): Rules {
  return {
    min: {
      value: minValue,
      message: t("Minimum value is {{number}}", { number: minValue }),
    },
  };
}

/**
 * hasMaxValue is shorthand for specifying only a maximum value requirement.
 * This is useful for number fields.
 * @param message should mention the field name, like "Age must be at most 129."
 */
export function hasMaxValue(maxValue: number): Rules {
  return {
    max: {
      value: maxValue,
      message: t("Maximum value is {{number}}", { number: maxValue }),
    },
  };
}

/**
 * hasMaxLength is shorthand for specifying only a maximum length requirement.
 *
 * @param message should mention the field name, like "Phone number should be at most 15 characters long."
 */
export function hasMaxLength(maxLength: number, message: string): Rules {
  return {
    maxLength: {
      value: maxLength,
      message,
    },
  };
}

/**
 * testMalanEmail verifies that an email address matches the rules implemented
 * by Malan.
 *
 * https://github.com/FreedomBen/malan/blob/9e3be0821ef0771ec9829f50146f36ae28296b09/lib/malan/accounts/user.ex#L278-L288
 */
export function testMalanEmail(value: string): boolean {
  const email = String(value).toLowerCase();
  if (email.length < 6 || email.length > 150) return false;
  if (/@.*@/.test(email)) return false;
  if (/^\|/.test(email)) return false;
  return /^[!#$%&'*+-/=?^_`{|}~A-Za-z0-9]{1,64}@[.-A-Za-z0-9]{1,63}\.[A-Za-z]{2,25}$/.test(
    email,
  );
}

/**
 * isEmail specifies that the field must meet email requirements.
 */
export function isEmail(): Rules {
  return {
    validate: {
      email: (value: string) =>
        testMalanEmail(value) || tString("Please enter a valid email address."),
    },
  };
}

/**
 * isPassword specifies that the field must meet password requirements.
 */
export function isPassword(): Rules {
  const length = 6;
  const message = t("Password should be at least {{length}} characters long.", {
    length,
  });
  return mergeRules(isRequired(message), hasMinLength(length, message));
}

/**
 * isPasswordConfirmation specifies that the field must match the value of another field.
 */
export function isPasswordConfirmation(passwordFieldName: string): Rules {
  return {
    validate: {
      passwordConfirmation: (value, data) => {
        return (
          value === data[passwordFieldName] || tString("Passwords must match.")
        );
      },
    },
  };
}

export const MAX_PHONE_NUMBER_LENGTH = 20;
export const MIN_PHONE_NUMBER_LENGTH = 8;
const ALLOWED_PHONE_NUMBER_CHARS = /^(\d|\(|\)|-|\+|\s)+$/;
/**
 * isPhoneNumber specifies that the field must meet phone number requirements.
 */
export function isPhoneNumber(): Rules {
  return mergeRules(
    hasMinLength(
      MIN_PHONE_NUMBER_LENGTH,
      t("Phone number must be at least {{number}} digits.", {
        number: MAX_PHONE_NUMBER_LENGTH,
      }),
    ),
    hasMaxLength(
      MAX_PHONE_NUMBER_LENGTH,
      t("Phone number cannot be more than {{number}} digits.", {
        number: MAX_PHONE_NUMBER_LENGTH,
      }),
    ),
    {
      pattern: {
        value: ALLOWED_PHONE_NUMBER_CHARS,
        message: t(
          "Phone number cannot contain letters or special characters, except for +, (, ), and -.",
        ),
      },
    },
  );
}

export const MAX_USER_AGE = 129;
export const MIN_USER_AGE = 13;

/**
 * isDateOfBirth specifies that the field must meet date of birth requirements.
 */
export function isDateOfBirth(): Rules {
  return mergeRules(isRequired(t("Please enter your date of birth.")), {
    validate: {
      dateOfBirth: (value?: Date) => {
        const invalidDate = t("Please enter a valid date.");
        // Invalid data (or value = undefined - though I believe the undefined case
        // will be handled by required)
        if (!value || value.toString() === "Invalid Date") return invalidDate;

        if (value.getTime() > new Date().getTime())
          return tString("Please enter a date in the past.");

        // Older than oldest age ever recorded
        const age = differenceInYears(new Date(), value);
        if (age > MAX_USER_AGE || age <= 0) return invalidDate;

        // Younger than allowed
        if (age < MIN_USER_AGE)
          return tString(
            "You must be at least {{minUserAge}} years old to have an Ameelio Connect account.",
            { minUserAge: MIN_USER_AGE },
          );
        // Checks passed
        return true;
      },
    },
  });
}

export function isMinorDateOfBirth(): Rules {
  return {
    validate: {
      minorDateOfBirth: (value: string) => {
        const isInvalidDate = Number.isNaN(new Date(value).getTime());
        if (isInvalidDate) {
          return tString("Please enter a date in the format {{format}}.", {
            format: inferDateFormat().toLowerCase().replace(/-/gi, "/"),
          });
        }

        // And also that the date means the search result is for a minor
        if (subYears(new Date(), MINOR_AGE_YEARS_CUTOFF) > new Date(value))
          return tString("Please enter a date after {{minorCutoffDate}}.", {
            minorCutoffDate: monthDateYearFormat.format(
              subYears(new Date(), MINOR_AGE_YEARS_CUTOFF),
            ),
          });
        return true;
      },
    },
  };
}

const ALLOWED_CHARACTERS =
  /^[\x20-\x7E|àèòùáéíóúñÑÁÉÍÓÚ¿¡ôêû|(?<=\w)['"’”\]]*$/m;
export function isStandardCharacters(): Rules {
  return {
    pattern: {
      value: ALLOWED_CHARACTERS,
      message: t(
        "Due to facility restrictions, emoji and special characters are not allowed.",
      ),
    },
  };
}

const URL_PATTERN = /^(https:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/; // NB https is optional in the pattern
export function isValidUrl(): Rules {
  return {
    pattern: {
      value: URL_PATTERN,
      message: t("Please enter a valid url."),
    },
  };
}
