import { useQuery } from "@apollo/client";
import { EventAvailable, Group, Search } from "@mui/icons-material";
import { Card, Divider, Stack } from "@mui/material";
import {
  Entitlement,
  FacilityFeature,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import { WEEKDAYS, WEEKDAY_NAMES } from "@src/lib/constants";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import SchedulingWindowSettingField from "@src/lib/SchedulingWindowSettingField";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import {
  BooleanSettingField,
  NumberSettingField,
  SelectSettingField,
} from "@src/lib/SettingField";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import { useTranslation } from "react-i18next";
import { GetFacilityDocument } from "../../graphql/GetFacility.generated";
import ScheduleTable from "../../lib/ScheduleTable";
import UnavailableSplashScreen from "../../lib/UnavailableSplashScreen";

export default function VideoCallsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();

  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const handleApolloError = useApolloErrorHandler();
  const { data } = useQuery(GetFacilitySchedulesDocument, {
    onError: handleApolloError,
    variables: { facilityId: facility.id },
  });

  const { updateProperty, updateProperties } = useUpdateFacilityProperty();

  const { data: facilityData } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id },
  });

  if (!data || !facilityData) return <SettingsSkeleton />;

  const { facility: loadedFacility } = facilityData;

  if (!loadedFacility.features.includes(FacilityFeature.VideoCall)) {
    return (
      <UnavailableSplashScreen
        header={t("Video calls")}
        subHeader={t(
          "Contact us about adding visitor video calls to the facility.",
        )}
        features={[
          {
            icon: <Group />,
            text: t(
              "Allow approved contacts to request video calls with residents",
            ),
          },
          {
            icon: <EventAvailable />,
            text: t("Require approval for each scheduled video call"),
          },
          {
            icon: <Search />,
            text: t(
              "Review and monitor video calls live and after they have happened",
            ),
          },
        ]}
        learnMoreText={t("Learn more about video calls")}
        learnMoreLink={"https://www.ameelio.org/child-products/video-calls"}
      />
    );
  }

  return (
    <>
      <Header
        title={t("Video calls")}
        subtitle={t(
          "Approved visitors may request video calls according to a defined schedule.",
        )}
      />
      <Stack spacing={2} p={2}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Scheduling")}</SectionTitle>
            <SchedulingWindowSettingField
              value={{
                cutoffDays: loadedFacility.personalVideoCutoff,
                windowWeeks: loadedFacility.personalVideoWindow,
              }}
              onChange={(data) =>
                updateProperties({
                  personalVideoCutoff: data.cutoffDays,
                  personalVideoWindow: data.windowWeeks,
                })
              }
            />
            <Divider />
            <BooleanSettingField
              label={t("Calls require reviews")}
              description={t(
                "Video calls will not be scheduled unless approved by staff.",
              )}
              value={loadedFacility.videoNeedsApproval}
              onChange={(value) => updateProperty("videoNeedsApproval", value)}
              canEdit={canEdit}
            />
            <Divider />
            <BooleanSettingField
              label={t("Calls require monitors")}
              description={t(
                "Video calls will not start unless a staff member is logged on to monitor it. This can be customized per resident during roster upload.",
              )}
              value={loadedFacility.defaultNeedsMonitor}
              onChange={(value) => updateProperty("defaultNeedsMonitor", value)}
              canEdit={canEdit}
            />
          </Stack>
        </Card>

        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Resident quotas")}</SectionTitle>
            <NumberSettingField
              label={t("Default weekly quota")}
              description={t(
                "The default number of video calls allowed each week. This can be customized per resident during roster upload.",
              )}
              value={loadedFacility.defaultCallQuota}
              onChange={(value) => updateProperty("defaultCallQuota", value)}
              validate={{
                min: (v) => v >= 0,
                max: (v) => v < 2147483647,
              }}
              errorMessages={{
                min: t("Cannot be negative."),
                max: t("This number is too large."),
              }}
              canEdit={canEdit}
            />
            <Divider />
            <SelectSettingField
              label={t("Start of week")}
              description={t(
                "The day of the week when the quota resets and new video calls may be scheduled.",
              )}
              value={loadedFacility.videoQuotaResetDay}
              render={(value) => WEEKDAY_NAMES[value]}
              onChange={(value) => updateProperty("videoQuotaResetDay", value)}
              selectOptions={WEEKDAYS.map((day) => ({
                value: day,
                name: WEEKDAY_NAMES[day],
              }))}
              canEdit={canEdit}
            />
          </Stack>
        </Card>

        <ScheduleTable
          title={t("Video call schedules")}
          schedules={data.facility.schedules.filter(
            (s) =>
              s.meetingType === MeetingType.VideoCall &&
              s.privacyLevels.includes(PrivacyLevel.Monitored),
          )}
          privacyLevel={PrivacyLevel.Monitored}
          meetingType={MeetingType.VideoCall}
        />
      </Stack>
    </>
  );
}
