import { useQuery } from "@apollo/client";
import { Card, Stack } from "@mui/material";
import { FacilityFeature, MeetingType, PrivacyLevel } from "@src/api/graphql";
import { GetFacilityDocument } from "@src/graphql/GetFacility.generated";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import NotFoundSplashScreen from "@src/lib/NotFoundSplashScreen";
import ScheduleTable from "@src/lib/ScheduleTable";
import SchedulingWindowSettingField from "@src/lib/SchedulingWindowSettingField";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { SectionTitle } from "@src/lib/typography";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import { useTranslation } from "react-i18next";

export default function ConfidentialInPersonVisitsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  const { data } = useQuery(GetFacilitySchedulesDocument, {
    onError: handleApolloError,
    variables: { facilityId: facility.id },
  });

  const { updateProperties } = useUpdateFacilityProperty();

  const { data: facilityData } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id },
  });

  if (!data || !facilityData) return <SettingsSkeleton />;

  const { facility: loadedFacility } = facilityData;

  if (
    !loadedFacility.features.includes(FacilityFeature.ConfidentialInPersonVisit)
  ) {
    return <NotFoundSplashScreen />;
  }

  return (
    <>
      <Header
        title={t("Confidential in-person visits")}
        subtitle={t(
          "Eligible visitors may request confidential in-person visits, which are unmonitored and scheduled separately from standard in-person visits.",
        )}
      />
      <Stack spacing={2} p={2}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Scheduling")}</SectionTitle>
            <SchedulingWindowSettingField
              value={{
                cutoffDays: loadedFacility.providerVisitCutoff,
                windowWeeks: loadedFacility.providerVisitWindow,
              }}
              onChange={(data) =>
                updateProperties({
                  providerVisitCutoff: data.cutoffDays,
                  providerVisitWindow: data.windowWeeks,
                })
              }
            />
          </Stack>
        </Card>

        <ScheduleTable
          title={t("Confidential in-person visit schedules")}
          schedules={data.facility.schedules.filter(
            (s) =>
              s.meetingType === MeetingType.InPersonVisit &&
              s.privacyLevels.includes(PrivacyLevel.UnmonitoredLogged),
          )}
          privacyLevel={PrivacyLevel.UnmonitoredLogged}
          meetingType={MeetingType.InPersonVisit}
        />
      </Stack>
    </>
  );
}
