import { useLazyQuery } from "@apollo/client";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import {
  countsInInterval,
  ElaboratedRatings,
  elaborateRatings,
} from "@src/pages/ActivityPage/ActivityUtils";
import { GetFacilityMetricsV2Document } from "@src/pages/ActivityPage/GetFacilityMetricsV2.generated";
import { useCallback, useMemo, useState } from "react";

interface MetricsData {
  total: number;
  adoptionRate: number;
  userRatings?: ElaboratedRatings;
  inmateRatings?: ElaboratedRatings;
  volume: Record<string, number>;
}

interface UseFacilityMetricsArgs {
  facilityId: string;
}

interface FetchMetricsArgs {
  reportStart: number;
  reportEnd: number;
}

export function useFacilityMetrics({ facilityId }: UseFacilityMetricsArgs) {
  const handleApolloError = useApolloErrorHandler();
  const [currentInterval, setCurrentInterval] = useState<{
    start: Date;
    end: Date;
  } | null>(null);

  const [getData, { data, loading }] = useLazyQuery(
    GetFacilityMetricsV2Document,
    {
      fetchPolicy: "cache-first",
      onError: handleApolloError,
    },
  );

  const fetchMetrics = useCallback(
    async ({ reportStart, reportEnd }: FetchMetricsArgs) => {
      setCurrentInterval({
        start: new Date(reportStart),
        end: new Date(reportEnd),
      });

      return getData({
        variables: {
          reportStart,
          reportEnd,
          facilityId,
        },
      });
    },
    [getData, facilityId],
  );

  const videoCalls: MetricsData | null = useMemo(() => {
    if (!data || !currentInterval) return null;
    return {
      total: data.facility.videoCallMetrics.count,
      adoptionRate: Math.round(
        (data.facility.videoCallMetrics.uniqueInmates /
          data.facility.inmateMetrics.count) *
          100,
      ),
      userRatings: elaborateRatings(
        data.facility.videoCallMetrics.ratingsByType.user,
      ),
      inmateRatings: elaborateRatings(
        data.facility.videoCallMetrics.ratingsByType.inmate,
      ),
      volume: countsInInterval(
        currentInterval,
        data.facility.videoCallMetrics.countByDay,
      ),
    };
  }, [data, currentInterval]);

  const voiceCalls: MetricsData | null = useMemo(() => {
    if (!data || !currentInterval) return null;
    return {
      total: data.facility.voiceCallMetrics.count,
      adoptionRate: Math.round(
        (data.facility.voiceCallMetrics.uniqueInmates /
          data.facility.inmateMetrics.count) *
          100,
      ),
      userRatings: elaborateRatings(
        data.facility.voiceCallMetrics.ratingsByType.user,
      ),
      inmateRatings: elaborateRatings(
        data.facility.voiceCallMetrics.ratingsByType.inmate,
      ),
      volume: countsInInterval(
        currentInterval,
        data.facility.voiceCallMetrics.countByDay,
      ),
    };
  }, [data, currentInterval]);

  const inPersonVisits: MetricsData | null = useMemo(() => {
    if (!data || !currentInterval) return null;
    return {
      total: data.facility.visitMetrics.count,
      adoptionRate: Math.round(
        (data.facility.visitMetrics.uniqueInmates /
          data.facility.inmateMetrics.count) *
          100,
      ),
      volume: countsInInterval(
        currentInterval,
        data.facility.visitMetrics.countByDay,
      ),
    };
  }, [data, currentInterval]);

  return {
    fetchMetrics,
    loading,
    data,
    videoCalls,
    voiceCalls,
    inPersonVisits,
  };
}
