import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreatePstnConnectionMutationVariables = Types.Exact<{
  input: Types.CreatePstnConnectionInput;
}>;


export type CreatePstnConnectionMutation = { __typename: 'Mutation', createPstnConnection: { __typename: 'CreatePstnConnectionResponse', connection: { __typename: 'Connection', id: Types.Scalars['ID']['input'], status: Types.ConnectionStatus, relationship: string, createdAt: Types.Scalars['Date']['input'], statusDetails: string | null, security: Array<Types.SecurityFeature> } } };


export const CreatePstnConnectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePstnConnection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePstnConnectionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPstnConnection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConnectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Connection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"relationship"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"statusDetails"}},{"kind":"Field","name":{"kind":"Name","value":"security"}}]}}]} as unknown as DocumentNode<CreatePstnConnectionMutation, CreatePstnConnectionMutationVariables>;