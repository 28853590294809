import { Tooltip } from "@ameelio/ui";
import { InfoOutlined } from "@mui/icons-material";

type Props = {
  message: string;
};

export default function Info({ message }: Props) {
  return (
    <Tooltip title={message}>
      <InfoOutlined fontSize="small" sx={{ verticalAlign: "middle", ml: 1 }} />
    </Tooltip>
  );
}
